import DefaultLayout from "../components/DefaultLayout";
function TermsConditions() {
  return (
    <>
      <DefaultLayout>
        <section className="bg-secondary py-5 pb-0">
          <div className="py-5 pb-0 container">
            <nav aria-label="breadcrumb" className="pt-3 mb-4">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Terms and Conditions
                </li>
              </ol>
            </nav>
            <div className="px-0 col-lg-8 col-md-10">
              <h1 className="pb-2">Terms and Conditions</h1>
            </div>
          </div>
        </section>
        <section className="position-relative bg-white rounded-xxl-4 mb-5 py-2 zindex-5 container">
          <div className="p-5">
            <p>
              <span>
                The following agreement captures the terms and conditions of use
                (&quot;<b>Agreement</b>&quot;), applicable to Your use of
                www.farmway.in (&quot;<b>Web Site</b>&quot;), which promotes
                business between suppliers and buyers globally. It is an
                agreement between You as the user of the Web Site/BAM Services
                and Bharat Agri Mart. (&quot;<b>BAM</b>&quot;). The expressions
                “You” “Your” or “User(s)” refers to any person who accesses or
                uses the Web Site for any purpose.
                <br />
                <br /> By subscribing to or interacting with other User(s) on or
                entering into negotiations in respect of sale or supply of goods
                or services on or using the Web Site or BAM Services in any
                manner for any purpose, You undertake and agree that You have
                fully read, understood and accepted the Agreement.
                <br />
                <br /> If You do not agree to or do not wish to be bound by the
                Agreement, You may not access or otherwise use the Web Site in
                any manner.
              </span>
            </p>

            <p>
              <b>
                <span>1. WEBSITE- MERELY A VENUE/PLATFORM</span>
              </b>
            </p>

            <p>
              <span>
                The Web Site acts as a match-making platform for User(s) to
                negotiate and interact with other User(s) for entering into
                negotiations in respect thereof for sale or supply of goods or
                services. BAM or www.farmway.in are not parties to any
                negotiations that take place between the User(s) of the Web Site
                and are further not parties to any agreement including an
                agreement for sale or supply of goods or services or otherwise,
                concluded between the User(s) of the Web Site.
                <br />
                <br /> BAM does not control and is not liable in respect of or
                responsible for the quality, safety, genuineness, lawfulness or
                availability of the products or services offered for
                sale&nbsp;on the Web Site or the ability of the User(s) selling
                or supplying the goods or services to complete a sale or the
                ability of User(s) purchasing goods or services to complete a
                purchase. This agreement shall not be deemed to create any
                partnership, joint venture, or any other joint business
                relationship between BAM and any other party. Users are solely
                responsible for their interactions and agreements made through
                the website.
                <br />
                <br />{" "}
              </span>
            </p>

            <p>
              <b>
                <span>2. SERVICES PROVIDED BY BAM</span>
              </b>
            </p>

            <p>
              <span>
                BAM provides the following services to its Customers and their
                respective definitions are classified hereunder: <br />
                <br />{" "}
              </span>
            </p>

            <ul>
              <li>
                <span>
                  &quot;&quot;: It is add-on service by BAM which gives its
                  User(s) priority listing in their chosen category of products.
                  By availing this service the User(s) will get benefits of
                  increased leads and enquiries.
                </span>
              </li>
              <li>
                <span>
                  &quot;&quot;: is a seal that User(s) gets after getting its
                  business-related documents and information verified.
                </span>
              </li>
              <li>
                <span>
                  &quot;&quot;: is a seal that User(s) gets after getting its
                  Organic certification related documents viz Scope Certificate,
                  Transaction Certification. Etc given by Organic certification
                  agencies/government departments and information verified.
                </span>
              </li>
              <li>
                <u>
                  <span>&quot;Verified&quot; User(s):</span>
                </u>
                <span>
                  {" "}
                  Users are said to be verified if any of their provided
                  primary/ secondary, mobile or email is verified by BAM.
                </span>
              </li>
            </ul>

            <p>
              <b>
                <span>3. USER(S) ELIGIBILITY</span>
              </b>
            </p>

            <p>
              <span>
                User(s) represent and warrant that they have the right to avail
                or use the services provided by BAM, including but limited to
                the Web Site or any other services provided by BAM in relation
                to the use of the Web Site (&quot;<b>BAM’s Services</b>&quot;).
                BAM’s Services can only be availed by those individuals or
                business entities, including sole proprietorship firms,
                companies and partnerships, which are authorised under
                applicable law to form legally binding agreements. As such,
                natural persons below 18 years of age and business entities or
                organisations that are not authorised by law to operate in India
                or other countries are not authorised to avail or use BAM’s
                Services.
                <br />
                <br /> User(s) agree to abide by the Agreement and any other
                rules and regulations imposed by the applicable law from time to
                time.&nbsp;BAM or the website shall have no liability to the
                User(s) or anyone else for any content, information or any other
                material transmitted over BAM’s Services, including any
                fraudulent, untrue, misleading, inaccurate, defamatory,
                offensive or illicit material and that the risk of damage from
                such material rests entirely with each User(s).The user shall do
                its own due diligence before entering into any transaction with
                other users on the website. BAM at it’s sole discretion reserves
                the right to refuse BAM’s Services to anyone at any time. BAM’s
                Services are not available and may not be availed or used by
                User(s) whose Accounts have been temporarily or indefinitely
                suspended by BAM.
              </span>
            </p>

            <p>
              <b>
                <span>4.USER(S) AGREEMENT</span>
              </b>
            </p>

            <p>
              <span>
                This Agreement applies to any person who accesses or uses the
                Web Site or uses BAM Services for any purpose.&nbsp;It also
                applies to any legal entity which may be represented by any
                person who accesses or uses the Web Site, under actual or
                apparent authority. User(s) may use this Web Site and/or BAM
                Services solely for their commercial/business purposes. The
                Users are strictly prohibited from transferring, sublicensing,
                or assigning any of their rights, access, or entitlements
                related to any services provided by BAM to any third party
                without obtaining explicit prior written consent from BAM.
                <br />
                <br /> This Agreement applies to all services offered on the Web
                Site and by BAM, collectively with any additional terms and
                conditions that may be applicable in respect of any specific
                service used or accessed by User(s) on the Web Site. In the
                event of any conflict or inconsistency between any provision of
                this Agreement and any additional terms and conditions
                applicable in respect of any service offered on the Web Site,
                such additional terms and conditions applicable in respect of
                that service shall prevail over this Agreement. This Agreement
                shall govern the User’s usage of BAM Services and, the User
                acknowledges that this Agreement shall supersede all or any
                term, agreement, contract executed between BAM and the User.
              </span>
            </p>

            <p>
              <span>
                <br />
                <b>5. AMENDMENT TO USER(S) AGREEMENT</b>
              </span>
            </p>

            <p>
              <span>
                BAM reserves the right to change, modify, amend, or update the
                Agreement from time to time and such amended provisions of the
                Agreement shall be effective immediately upon being posted on
                the Web Site. If You do not agree to such provisions,&nbsp;you
                must stop using the service with immediate effect<b>.</b>
                &nbsp;Your continuous use of the service will be deemed to
                signify Your acceptance of the amended provisions of the
                Agreement.
              </span>
            </p>

            <p>
              <b>
                <span>&nbsp;</span>
              </b>
            </p>

            <p>
              <b>
                <span>&nbsp;</span>
              </b>
            </p>

            <p>
              <b>
                <span>6. INTELLECTUAL PROPERTY RIGHTS</span>
              </b>
            </p>

            <p>
              <span>
                BAM is the sole owner and the lawful licensee of all the rights
                to the Web Site and its content (&quot;Web Site Content&quot;).
                Web Site Content means the design, layout, text, images,
                graphics, sound, video etc. of or made available on the Web
                Site. The Web Site Content embodies trade secrets and other
                intellectual property rights protected under worldwide copyright
                and other applicable laws pertaining to intellectual property.
                All title, ownership and intellectual property rights in the Web
                Site and the Web Site Content shall remain in BAM, its
                affiliates or licensor’s of the Web Site content, as the case
                may be.
                <br />
                <br /> All rights, not otherwise claimed under this Agreement by
                BAM, are hereby reserved. Any information or advertisements
                contained on, distributed through, or linked, downloaded or
                accessed from any of the services contained on the Web Site or
                any offer displayed on or in connection with any service offered
                on the Web Site (&quot;Website Information&quot;) is intended,
                solely to provide general information for the personal use of
                the User(s), who fully accept any and all responsibility and
                liabilities arising from and out of the use of such Information.
                BAM does not represent, warrant or endorse in any manner the
                accuracy or reliability of Website Information, or the quality
                of any products and/or services obtained by the User(s) as a
                result of any Website Information.
                <br />
                <br /> For any content and or link uploaded to the Website by
                the User from social media viz YouTube, Facebook, Instragram
                etc., the User agrees to abide and accepts, the terms of service
                of such social media viz YouTube, Facebook, Instragram etc.,
                available at their (social media) platform .<br />
                <br /> The Information is provided “as is” with no guarantee of
                completeness, accuracy, timeliness or of the results obtained
                from the use of the Information, and without warranty of any
                kind, express or implied, including, but not limited to
                warranties of performance, merchantability and fitness for a
                particular purpose. Nothing contained in the Agreement shall to
                any extent substitute for the independent investigations and the
                sound technical and business judgment of the User(s). In no
                event shall BAM be liable for any direct, indirect, incidental,
                punitive, or consequential damages of any kind whatsoever with
                respect to BAM’s Services. User(s) hereby acknowledge that any
                reliance upon the Information shall be at their sole risk and
                further understand and acknowledge that the Information has been
                compiled from publicly aired and published sources. BAM respects
                the rights of such entities and cannot be deemed to be
                infringing on the respective copyrights or businesses of such
                entities. BAM reserves the right, in its sole discretion and
                without any obligation, to make improvements to, or correct any
                error or omissions in any portion of the Information.
              </span>
            </p>

            <ul>
              <li>
                <b>
                  <span>Trademark</span>
                </b>
                <span>
                  <br />
                  &quot;Bharat Agri Mart&quot; and related icons and logos are
                  registered trademarks or trademarks or service marks of BAM in
                  various jurisdictions and are protected under applicable
                  copyright, trademark and other proprietary and intellectual
                  property rights laws. The unauthorized adoption copying,
                  modification, use or publication of these marks is strictly
                  prohibited.
                </span>
              </li>
              <li>
                <b>
                  <span>Copyright</span>
                </b>
                <span>
                  <br />
                  All Web Site Content including Website Information is
                  copyrighted to BAM excluding any third-party content and any
                  links to any third-party websites being made available or
                  contained on the Web Site. User(s) may not use any trademark,
                  service mark or logo of any independent third parties without
                  prior written approval from such parties.
                  <br />
                  <br />
                  User(s) acknowledge and agree that BAM is not an arbiter or
                  judge of disputes concerning intellectual property rights and
                  as such cannot verify that User(s) selling or supplying
                  merchandise or providing services on the Web Site have the
                  right to sell the merchandise or provide the services offered
                  by such User(s). BAM encourages User(s) to assist BAM in
                  identifying listings on the Web Site which in the User(s)
                  knowledge or belief infringe their rights. User(s) further
                  acknowledge and agree by taking down a listing, BAM does not
                  and cannot be deemed to be endorsing a claim of infringement
                  and further that in those instances in which BAM declines to
                  take down a listing, BAM does not and cannot be deemed to be
                  endorsing that the listing is not infringing of third party
                  rights or endorsing any sale or supply of merchandise or
                  services pursuant to or on account of such listing.
                  <br />
                  <br />
                  BAM respects the intellectual property rights of others, and
                  we expect our User(s) to do the same. User(s) agree to not
                  copy, download or reproduce the Web Site Content, Information
                  or any other material, text, images, video clips, directories,
                  files, databases or listings available on or through the Web
                  Site (&quot;
                  <b>BAM Content</b>&quot;) for the purpose of re-selling or
                  re-distributing, mass mailing (via email, wireless text
                  messages, physical mail or otherwise) operating a business
                  competing with BAM, or otherwise commercially exploiting the
                  BAM Content unless otherwise agreed between the parties.
                  Systematic retrieval of BAM Content to create or compile,
                  directly or indirectly, a collection, compilation, database or
                  directory (whether through robots, spiders, automatic devices
                  or manual processes) without written permission from BAM is
                  prohibited.
                  <br />
                  <br />
                  In addition, use of the BAM Content for any purpose not
                  expressly permitted in this Agreement is prohibited and
                  entitles BAM to initiate appropriate legal action. User(s)
                  agree that as a condition of their access to and use of BAM's
                  Services, they will not use BAM’s Services to infringe the
                  intellectual property rights of any third parties in any way.
                  BAM reserves the right to terminate the right of any User(s)
                  to access or use BAM’s Services for any infringement of the
                  rights of third parties in conjunction with use of the BAM’s
                  Service, or in the event BAM is of the believes that User(s)
                  conduct is prejudicial to the interests of BAM, its
                  affiliates, or other User(s), or for any other reason, at
                  BAM’s sole discretion, with or without cause.
                </span>
              </li>
              <li>
                <b>
                  <span>URL's/Sub-Domain</span>
                </b>
                <span>
                  <br />
                  URL’s/ Sub-domain names assigned by BAM to User(s) (including
                  both paid and free User(s)) are the exclusive property of BAM
                  and it cannot be assumed to be permanent in any case. BAM
                  reserves the right, without prior notice, at any point of
                  time, to suspend or terminate or restrict access to or edit
                  any URL's/Sub-domain names. IN ALL SUCH CASES, BAM WILL NOT BE
                  LIABLE TO ANY PARTY FOR ANY DIRECT, INDIRECT, SPECIAL OR OTHER
                  CONSEQUENTIAL DAMAGES, INCLUDING, WITHOUT LIMITATION, ANY LOST
                  PROFITS, BUSINESS INTERRUPTION OR OTHERWISE.
                  <br />
                  BAM may allow User(s) access to content, products or services
                  offered by third parties through hyperlinks (in the form of
                  word link, banners, channels or otherwise) to the websites
                  offered by such third parties (&quot;
                  <b>Third Party Websites</b>&quot;). BAM advises its User(s) to
                  read the terms and conditions of use and/or privacy policies
                  applicable in respect of such Third Party Websites prior to
                  using or accessing such Third Party Websites. Users
                  acknowledge and agree that BAM has no control over any content
                  offered on Third Party Websites, does not monitor such Third
                  Party Websites, and shall in no manner be deemed to be liable
                  or responsible to any person for such Third Party Sites, or
                  any content, products or services made available thereof.
                </span>
              </li>
            </ul>

            <p>
              <b>
                <span>7. LINKS TO THIRD PARTY SITES</span>
              </b>
            </p>

            <p>
              <span>
                Links to third party sites are provided on Web Site as a
                convenience to User(s). User(s) acknowledge and agree that BAM
                does not have any control over the content of such websites and/
                or any information, resources or materials provided therein.
                <br />
                <br /> BAM may allow User(s) access to content, products or
                services offered by third parties through hyperlinks (in the
                form of word link, banners, channels or otherwise) to the
                websites offered by such third parties (&quot;
                <b>Third Party Websites</b>&quot;). BAM advises its User(s) to
                read the terms and conditions of use and/or privacy policies
                applicable in respect of such Third Party Websites prior to
                using or accessing such Third Party Websites. Users acknowledge
                and agree that BAM has no control over any content offered on
                Third Party Websites, does not monitor such Third Party
                Websites, and shall in no manner be deemed to be liable or
                responsible to any person for such Third Party Sites, or any
                content, products or services made available thereof.
                <br />
                <br />
                <b>8.TERMINATION</b>
                <br /> Most content and some of the features on the Web Site are
                made available to User(s) free of charge. However, BAM reserves
                the right to terminate access to certain areas or features of
                the Web Site (to paying or registered User(s)) at any time
                without assigning any reason and with or without notice to such
                User(s). BAM also reserves the universal right to deny access to
                particular User(s) to any or all of its services or content
                without any prior notice or explanation in order to protect the
                interests of BAM and/ or other User(s) of the Web Site. BAM
                further reserves the right to limit, deny or create different
                access to the Web Site and its features with respect to
                different User(s), or to change any or all of the features of
                the Web Site or introduce new features without any prior notice
                to User(s).
                <br /> BAM reserves the right to terminate the
                membership/subscription of any User(s) temporarily or
                permanently for any of the following reasons:
              </span>
            </p>

            <p>
              <span>
                (a) If any false information in connection with their account
                registered with BAM is provided by such User(s), or if such
                User(s) are engaged in fraudulent or illegal
                activities/transactions.
              </span>
            </p>

            <p>
              <span>
                (b) If such User(s) breaches any provisions of the Agreement.
              </span>
            </p>

            <p>
              <span>
                (c) If such User(s) utilizes the Web Site to send spam messages
                or repeatedly publish the same product information.
              </span>
            </p>

            <p>
              <span>
                (d) If such User(s) posts any material that is not related to
                trade or business cooperation.
              </span>
            </p>

            <p>
              <span>
                (e) If such User(s) impersonates or unlawfully uses another
                person’s or business entity’s name to post information or
                conduct business in any manner.
              </span>
            </p>

            <p>
              <span>
                (f) If such User(s) is involved in unauthorized access, use,
                modification, or control of the Web Site database, network or
                related services.
              </span>
            </p>

            <p>
              <span>
                (g) If such User(s) obtains by any means another registered
                User(s) Username and/or Password.
              </span>
            </p>

            <p>
              <span>
                (h) Or any other User(s) activity that may not be in accordance
                with the ethics and honest business practices.
              </span>
            </p>

            <p>
              <span>
                <br />
                If BAM terminates the membership of any registered User(s)
                including those User(s) who have subscribed for the paid
                services of BAM, such person will not have the right to re-enrol
                or join the Web Site under a new account or name unless invited
                to do so in writing by BAM. In any case of termination, no
                subscription/membership fee/charges paid by the User(s) will be
                refunded. However, In case of discontinuation of services due to
                the reasons other than mentioned hereinabove, BAM may on its
                discretion, process the pro rata refund after adjustment of such
                other charges as it deems fit.
                <br />
                User(s) acknowledge that inability to use the Web Site wholly or
                partially for whatever reason may have adverse effects on their
                business. User(s) hereby agree that in no event shall BAM be
                liable to any User(s) or any third parties for any inability to
                use the Web Site (whether due to disruption, limited access,
                changes to or termination of any features on the Web Site or
                otherwise), any delays, errors or omissions with respect to any
                communication or transmission, or any damage (direct, indirect,
                consequential or otherwise) arising from the use of or inability
                to use the Web Site or any of its features
              </span>
            </p>

            <p>
              <b>
                <span>9. REGISTERED USER(S)</span>
              </b>
            </p>

            <p>
              <span>
                To become a registered User(s) of the Web Site a proper
                procedure has been made available on the Web Site which is for
                the convenience of User(s) so that they can easily use the
                website.
                <br />
                <br /> User(s) can become registered User(s) by filling an
                on-line registration form on the Web Site by providing the
                required information (including name, contact information,
                details of User(s) business, etc.). BAM will establish an
                account (&quot;Account&quot;) for the User(s) upon successful
                registration for log-in access to the User(s)’s Account. BAM may
                at its sole discretion assign to User(s) upon registration a
                web-based email or messaging account (“Email Account”) with
                limited storage space to send or receive emails or messages.
                Users will be responsible for the content of all the messages
                communicated through the account.
                <br />
                <br />
                User(s) registering on the Web Site on behalf of business
                entities represent and warrant that: (a) they have the requisite
                authority to bind such business entity this Agreement; (b) the
                address provided by such User(s) at the time of registration is
                the principal place of business of such business entity; and (c)
                all other information provided to BAM during the registration
                process is true, accurate, current and complete. For purposes of
                this provision, a branch or representative office of a User(s)
                will not be considered a separate entity and the principal place
                of business of the User(s) will be deemed to be that of its head
                office.
                <br />
                <br />
                User(s) agree that by registering on the Web Site, they consent
                to the inclusion of their personal data in BAM’s on-line
                database and authorize BAM to share such information with other
                User(s). BAM may refuse registration and deny the membership to
                any User(s) for whatever reason. BAM may suspend or terminate a
                registered membership at any time without any prior notification
                in interest of BAM or general interest of its User(s) without
                assigning any reason thereof and there shall arise no further
                liability on BAM of whatsoever nature due to the suspension or
                termination of the User account. User(s) registered on the Web
                Site are in no manner a part of or affiliated to BAM.
                <br />
                <br /> User(s) further agree and consent to be contacted by BAM
                through phone calls, SMS notifications or any other means of
                communication, in respect to the services provided by BAM even
                if contact number(s) provided to BAM upon registration are on Do
                Not Call Registry.
              </span>
            </p>

            <p>
              <b>
                <span>10. DATA PROTECTION</span>
              </b>
            </p>

            <p>
              <span>
                Personal information given by User(s) during the use of the Web
                Site is governed by BAM’s privacy policy (&quot;
                <b>Privacy Policy</b>&quot;). Please refer privacy policy given
                in the <a href="http://www.farmway.in">www.farmway.in</a>.
              </span>
            </p>

            <p>
              <span>
                <br />
                <b>11. POSTING YOUR CONTENT ON WEBSITE</b>
              </span>
            </p>

            <p>
              <span>
                Some content displayed on the Web Site is provided or posted by
                third parties. User(s) can post their content on some of the
                sections/services of the Web Site using the self-help submit and
                edit tools made at the respective sections of the Web Site.
                User(s) may need to register and/or pay for using or availing
                some of these services.
                <br />
                <br /> User(s) understand and agree that BAM in such case is not
                the author of the content and that neither BAM nor any of its
                affiliates, directors, officers or employees have entered into
                any arrangement including any agreement of sale or agency with
                such third parties by virtue of the display of such content on
                the Web Site. User(s) further understand and agree BAM is not
                responsible for the accuracy, propriety, lawfulness or
                truthfulness of any third party content made available on the
                Web Site and shall not be liable to any User(s) in connection
                with any damage suffered by the User(s) on account of the
                User(s)’s reliance on such content. BAM shall not be liable for
                a User(s) activities on the Web Site, and shall not be liable to
                any person in connection with any damage suffered by any person
                as a result of any User's conduct.
                <br />
                <br /> User(s) solely represent, warrant and agree to:
                <br />
                <br /> (a) provide BAM with true, accurate, current and complete
                information to be displayed on the Web Site;
                <br /> (b) maintain and promptly amend all information provided
                on the Web Site to keep it true, accurate, current and complete.
                <br /> User(s) hereby grant BAM an irrevocable, perpetual,
                worldwide and royalty-free, sub-licensable (through multiple
                tiers) license to display and use all information provided by
                them in accordance with the purposes set forth in the Agreement
                and to exercise the copyright, publicity and database rights
                User(s) have in such material or information, in any form of
                media, third party copyrights, trademarks, trade secret rights,
                patents and other personal or proprietary rights affecting or
                relating to material or information displayed on the Web Site,
                including but not limited to rights of personality and rights of
                privacy, or affecting or relating to products that are offered
                or displayed on the Web Site (hereafter referred to as
                &quot;Third Party Rights&quot;).
                <br />
                <br /> User(s) hereby represent, warrants and agree that User(s)
                shall be solely responsible for ensuring that any material or
                information posted by User(s) on the Web Site or provided to the
                Web Site or authorized by the User(s) for display on the Web
                Site, does not, and that the products represented thereby do
                not, violate any Third Party Rights, or is posted with the
                permission of the owner(s) of such Third Party Rights. User(s)
                hereby represent, warrant and agree that they have the right to
                manufacture, offer, sell, import and distribute the products
                offered and displayed on the Web Site, and that such
                manufacture, offer, sale, importation and/or distribution of
                those products violates no Third Party Rights.
                <br />
                <br /> User(s) agree that they will not use BAM Content and/or
                BAM’s Services to send junk mail, chain letters or spamming.
                Further, registered User(s) of the Web Site agree that they will
                not use the Email Account to publish, distribute, transmit or
                circulate any unsolicited advertising or promotional
                information. User(s) further hereby represent, warrant and agree
                (i) to host, display, upload, modify, publish, transmit, store,
                update or share; or (ii) submit to BAM for display on the Web
                Site or transmit or sought to be transmitted through BAM’s
                Services any content, material or information that does not and
                shall at no point:
                <br />
                <br />{" "}
              </span>
            </p>

            <ul>
              <li>
                <span>
                  Contain fraudulent information or make fraudulent offers of
                  items or involve the sale or attempted sale of counterfeit or
                  stolen items or items whose sales and/or marketing is
                  prohibited by applicable law, or otherwise promote other
                  illegal activities;
                </span>
              </li>
              <li>
                <span>
                  Belong to another person and to which User(s) do not have any
                  right to;
                </span>
              </li>
              <li>
                <span>
                  Be part of a scheme to defraud other User(s) of the Web Site
                  or for any other unlawful purpose;
                </span>
              </li>
              <li>
                <span>
                  Be intended to deceive or mislead the addressee about the
                  origin of such messages or knowingly and intentionally is used
                  to communicate any information which (i) is patently false or
                  grossly offensive or menacing/misleading in nature but may
                  reasonably be perceived as a fact; or (ii) harass a person,
                  entity or agency for financial gain or to cause any injury to
                  any person;
                </span>
              </li>
              <li>
                <span>
                  Relate to sale of products or services that infringe or
                  otherwise abet or encourage the infringement or violation of
                  any third party's copyright, patent, trademarks, trade secret
                  or other proprietary right or rights of publicity or privacy,
                  or any other Third Party Rights;
                </span>
              </li>
              <li>
                <span>
                  Violate any applicable law, statute, ordinance or regulation
                  (including without limitation those governing export control,
                  consumer protection, unfair competition, anti-discrimination
                  or false advertising);
                </span>
              </li>
              <li>
                <span>
                  Relate to any controversial weapons, cluster munitions or
                  anti-personnel mines and other such defense equipment;
                </span>
              </li>
              <li>
                <span>
                  Be defamatory, abusive libelous, unlawfully threatening,
                  unlawfully harassing, grossly harmful, indecent, seditious,
                  blasphemous, pedophilic, hateful, invasive of another’s
                  privacy, including bodily privacy racially, ethnically
                  objectionable, disparaging, relating or encouraging money
                  laundering or gambling, leading to breach of confidence, or
                  otherwise unlawful or objectionable in any manner whatever;
                </span>
              </li>
              <li>
                <span>
                  Be vulgar, obscene or contain or infer any pornography or
                  sex-related merchandising or any other content or otherwise
                  promotes sexually explicit materials or is otherwise harmful
                  to minors;
                </span>
              </li>
              <li>
                <span>
                  Promote discrimination based on race, sex, religion,
                  nationality, disability, sexual orientation or age;
                </span>
              </li>
              <li>
                <span>
                  Contain any material that constitutes unauthorized advertising
                  or harassment (including but not limited to spamming), invades
                  anyone's privacy or encourages conduct that would constitute a
                  criminal offense, give rise to civil liability, or otherwise
                  violate any law or regulation;
                </span>
              </li>
              <li>
                <span>
                  Solicit business from any User(s) in connection with a
                  commercial activity that competes with BAM;
                </span>
              </li>
              <li>
                <span>
                  Threaten the unity, integrity, defence, security or
                  sovereignty of India, friendly relations with foreign states,
                  or public order or causes incitement to the commission of any
                  cognisable offence or prevents investigation of any offence or
                  is insulting any other nation;
                </span>
              </li>
              <li>
                <span>
                  Contain any computer viruses or other destructive devices and
                  codes that have the effect of damaging, interfering with,
                  intercepting or expropriating any software or hardware system,
                  data or personal information or that are designed to
                  interrupt, destroy or limit the functionality of any computer
                  resource;
                </span>
              </li>
              <li>
                <span>
                  Link directly or indirectly to or include descriptions of
                  goods or services that are prohibited under the prevailing
                  law; or Otherwise create any liability for BAM or its
                  affiliates.
                </span>
              </li>
            </ul>

            <p>
              <span>
                BAM reserves the right in its sole discretion to remove any
                material/content/photos/offers displayed on the Web Site which
                in BAM’s reasonable belief is unlawful or could subject BAM to
                liability or in violation of the Agreement or is otherwise found
                inappropriate in BAM's opinion. BAM reserves the right to
                cooperate fully with governmental authorities, private
                investigators and/or injured third parties in the investigation
                of any suspected criminal or civil wrongdoing.
                <br />
                <br /> In connection with any of the foregoing, BAM reserves the
                right to suspend or terminate the Account of any User(s) as
                deemed appropriate by BAM at its sole discretion. User(s) agree
                that BAM shall have no liability to any User(s), including
                liability in respect of consequential or any other damages, in
                the event BAM takes any of the actions mentioned in this
                provision.
                <br />
                <br /> User(s) understand and agree that the Web Site acts as a
                content integrator and is not responsible for the information
                provided by User(s) displayed on the Web Site. BAM does not have
                any role in developing the content displayed on the Web Site.
                BAM has the right to promote any content including text, images,
                videos, brochures etc. provided by User(s) on various platforms
                owned by the company.
              </span>
            </p>

            <p>
              <b>
                <span>12. INTERACTION BETWEEN USERS</span>
              </b>
            </p>

            <p>
              <span>
                <br />
                BAM provides an on-line platform to facilitate interaction
                between buyers and suppliers of products and services. BAM does
                not represent the seller or the buyer in transactions and does
                not charge any commission for enabling any transaction. BAM does
                not control and is not liable to or responsible for the quality,
                safety, lawfulness or availability of the products or services
                offered for sale on the Web Site or the ability of the suppliers
                to complete a sale or the ability of buyers to complete a
                purchase. User(s) are cautioned that there may be risks of
                dealing with foreign nationals or people acting under false
                pretences on the Web Site. Web Site uses several tools and
                techniques to verify the accuracy and authenticity of the
                information provided by User(s). BAM however, cannot and does
                not confirm each User(s)’s purported identity on the Web Site.
                BAM encourages User(s) to evaluate the User(s) with whom they
                would like to deal with and use the common prudence while
                dealing with them.
                <br />
                <br />
                User(s) agree to fully assume the risks of any transactions
                (&quot;
                <b>Transaction Risks</b>&quot;) conducted on the basis of any
                content, information or any other material provided on the Web
                Site and further assume the risks of any liability or harm of
                any kind arising due to or caused in connection with any
                subsequent activity relating to any products or services that
                are the subject of any such transaction.
                <br />
                <br />
              </span>
            </p>

            <ul>
              <li>
                <span>
                  Such risks include, but are not limited to, misrepresentation
                  of products and services, fraudulent schemes, unsatisfactory
                  quality, failure to meet specifications, defective or
                  dangerous products, unlawful products, delay or default in
                  delivery or payment, cost miscalculations, breach of warranty,
                  breach of contract and transportation accidents.
                </span>
              </li>
              <li>
                <span>
                  Such risks also include the risks that the manufacture,
                  importation, distribution, offer, display, purchase, sale
                  and/or use of products or services offered or displayed on the
                  Web Site may violate or may be asserted to violate Third Party
                  Rights, and the risk that that User(s) may incur costs of
                  defense or other costs in connection with third parties'
                  assertion of Third Party Rights, or in connection with any
                  claims by any party that they are entitled to defense or
                  indemnification in relation to assertions of rights, demands
                  or claims by Third Party Rights claimants.
                </span>
              </li>
              <li>
                <span>
                  Such risks further include the risks that are the purchasers,
                  end-users of products or others claiming to have suffered
                  injuries or harms relating to product originally obtained by
                  User(s) of the Web Site as a result of purchase and sale
                  transactions in connection with using any content, information
                  or any other material provided on the Web Site may suffer
                  harms and/or assert claims arising from their use of such
                  products.
                </span>
              </li>
            </ul>

            <p>
              <span>
                User(s) agree that BAM shall not be liable or responsible for
                any damages, liabilities, costs, harms, inconveniences, business
                disruptions or expenditures of any kind that may occur/arise as
                a result of or in connection with any Transaction Risks. User(s)
                are solely responsible for all of the terms and conditions of
                the transactions conducted on, through or as a result of use of
                any content, information or any other material provided on the
                Web Site , including, without limitation, terms regarding
                payment, returns, warranties, shipping, insurance, fees, taxes,
                title, licenses, fines, permits, handling, transportation and
                storage. In the event of a dispute with any party to a
                transaction, User(s) agrees to release and indemnify BAM (and
                our agents, affiliates, directors, officers and employees) from
                all claims, demands, actions, proceedings, costs, expenses and
                damages (including without limitation any actual, special,
                incidental or consequential damages) arising out of or in
                connection with such transaction&nbsp;
                <br />
                <br /> Bharat Agri Mart reserves the right to
                add/modify/discontinue any of the features offered on BAM’s
                Services.
                <br />
                <br />
                <b>13. LIMITATION OF LIABILITY/DISCLAIMER</b>
              </span>
            </p>

            <p>
              <span>
                <br />
                The features and services on the Web Site are provided on an
                &quot; as is &quot; and &quot; as available &quot; basis, and
                BAM hereby expressly disclaims any and all warranties, express
                or implied, including but not limited to any warranties of
                condition, quality, durability, performance, accuracy,
                reliability, merchantability or fitness for a particular
                purpose. All such warranties, representations, conditions,
                undertakings and terms are hereby excluded. BAM makes no
                representations or warranties about the validity, accuracy,
                correctness, reliability, quality, stability or completeness of
                any information provided on or through the Web Site including
                display or listing of tenders on the Web Site which in no manner
                is endorsed by BAM. BAM has no association of whatsoever nature
                with the publisher and/or the published contents. Moreover, BAM
                does not facilitate or participate in any sale, delivery,
                transaction and / or storage related to any product including
                but not limited to controversial weapons, cluster munitions or
                anti-personnel mines and other such defense equipment. BAM does
                not represent or warrant that the manufacture, importation,
                distribution, offer, display, purchase, sale and/or use of
                products or services offered or displayed on the Web Site does
                not violate any Third Party Rights; and BAM makes no
                representations or warranties of any kind concerning any product
                or service offered or displayed on the Web site. Any material
                downloaded or otherwise obtained through the Web site is at the
                User(s) sole discretion and risk and the User(s) is solely
                responsible for any damage to its computer system or loss of
                data that may result from the download of any such material. No
                advice or information, whether oral or written, obtained by the
                User(s) from Web Site or through or from the Web Site shall
                create or be deemed to create any warranty not expressly stated
                herein.
                <br />
                <br />
                Under no circumstances shall BAM be held liable for any delay or
                failure or disruption of the content or services delivered
                through the Web Site resulting directly or indirectly from acts
                of nature, forces or causes beyond its reasonable control,
                including without limitation, Internet failures, computer,
                telecommunications or any other equipment failures, electrical
                power failures, strikes, labour disputes, riots, insurrections,
                civil disturbances, shortages of labour or materials, fires,
                flood, storms, explosions, Acts of God, natural calamities, war,
                governmental actions, orders of domestic or foreign courts or
                tribunals or non-performance of third parties. User(s) hereby
                agree to indemnify and save BAM, its affiliates, directors,
                officers and employees harmless, from any and all losses,
                claims, liabilities (including legal costs on a full indemnity
                basis) which may arise from their use of the Web Site (including
                but not limited to the display of User(s) information on the Web
                Site) or from User(s)’s breach of any of the terms and
                conditions of this Agreement. User(s) hereby further agree to
                indemnify and save BAM, its affiliates, directors, officers and
                employees harmless, from any and all losses, claims, liabilities
                (including legal costs on a full indemnity basis) which may
                arise from User(s)’s breach of any representations and
                warranties made by the User(s) to BAM.
                <br />
                <br />
                User(s) hereby further agree to indemnify and save BAM, its
                affiliates, directors, officers and employees harmless, from any
                and all losses, claims, liabilities (including legal costs on a
                full indemnity basis) which may arise, directly or indirectly,
                as a result of any claims asserted by Third Party Rights
                claimants or other third parties relating to products offered or
                displayed on the Web Site. User(s) hereby further agree that BAM
                is not responsible and shall have no liability for any material
                posted by other User(s) or any other person, including
                defamatory, offensive or illicit material and that the risk of
                damage from such material rests entirely with the User(s). BAM
                reserves the right, at its own expense, to assume the exclusive
                defense and control of any matter otherwise subject to
                indemnification by any User(s), in which event such User(s)
                shall cooperate with BAM in asserting any available defences.
                <br />
                <br />
                &nbsp;BAM shall not be liable for any special, direct, indirect,
                punitive, incidental or consequential damages or any damages
                whatsoever (including but not limited to damages for loss of
                profits or savings, business interruption, loss of information),
                whether in contract, negligence, tort, strict liability or
                otherwise or any other damages resulting from any of the
                following:
              </span>
            </p>

            <ul>
              <li>
                <span>The use or the inability to use the Web Site;</span>
              </li>
              <li>
                <span>
                  Any defect in goods, samples, data, information or services
                  purchased or obtained from a User(s) or a third-party service
                  provider through the web site;
                </span>
              </li>
              <li>
                <span>
                  Violation of Third Party Rights or claims or demands that
                  User(s) manufacture, importation, distribution, offer,
                  display, purchase, sale and/or use of products or services
                  offered or displayed on the web site may violate or may be
                  asserted to violate Third Party Rights; or claims by any party
                  that they are entitled to defense or indemnification in
                  relation to assertions of rights, demands or claims by Third
                  Party Rights claimants;
                </span>
              </li>
              <li>
                <span>
                  Unauthorized access by third parties to data or private
                  information of any User(s);
                </span>
              </li>
              <li>
                <span>
                  Statements or conduct of any User(s) of the web site; or
                </span>
              </li>
              <li>
                <span>
                  Any matters relating to Premium Services however arising,
                  including negligence.
                </span>
              </li>
            </ul>

            <p>
              <b>
                <span>14. GOVERNING LAW AND DISPUTE RESOLUTIONS</span>
              </b>
            </p>

            <p>
              <span>
                This Agreement and the Privacy Policy shall be governed in all
                respects by the laws of Indian Territory. BAM considers itself
                and intends itself to be subject to the jurisdiction of the
                Courts of Coimbatore, Tamil Nadu, India only. The parties to
                this Agreement hereby submit to the exclusive jurisdiction of
                the courts of Coimbatore, Tamil Nadu, India.
              </span>
            </p>

            <p>
              <b>
                <span>15. REFUND POLICY</span>
              </b>
            </p>

            <p>
              <span>
                User(s) hereby agree that all payments for BAM
                monthly/Quarterly/Half Yearly or annual subscriptions are final
                and non-refundable. Once a payment is processed, no refund will
                be issued, regardless of usage or cancellation or
                deactivation/deletion of membership account with BAM, before the
                end of the subscription period. BAM encourages User(s) to
                consider your/their subscription purchase carefully.
                <br />
                <br />
                <b>16. MISCELLANEOUS</b>
              </span>
            </p>

            <ul>
              <li>
                <span>
                  Headings for any section of the Agreement are for reference
                  purposes only and in no way define, limit, construe or
                  describe the scope or extent of such section.
                </span>
              </li>
              <li>
                <span>
                  BAM’s failure to enforce any right or failure to act with
                  respect to any breach by a User(s) under the Agreement and/or
                  Privacy Policy will not be deemed to be a BAM’s waiver of that
                  right or BAM's waiver of the right to act with respect with
                  subsequent or similar breaches.
                </span>
              </li>
              <li>
                <span>
                  BAM shall have the right to assign its obligations and duties
                  in this Agreement and in any other agreement relating BAM’s
                  Services to any person or entity
                </span>
              </li>
              <li>
                <span>
                  If any provision of this Agreement is held to be invalid or
                  unenforceable, such provision shall be struck out and the
                  remaining provisions of the Agreement shall be enforced.
                </span>
              </li>
              <li>
                <span>
                  All calls to Bharat Agri Mart are completely confidential.
                  However, Your call may be recorded to ensure quality of
                  service. Further, for training purpose and to ensure excellent
                  customer service, calls from Bharat Agri Mart may be monitored
                  and recorded.
                </span>
              </li>
              <li>
                <span>
                  Any complaints or concerns with regards to any content on
                  BAM’s Services or any breach of this Agreement or Privacy
                  Policy can be directed to email signed with the electronic
                  signature sent to&nbsp;bamadmin@bharatagrimart.com
                </span>
              </li>
              <li>
                <span>
                  The Agreement and the Privacy Policy constitute the entire
                  agreement between the User(s) and BAM with respect to access
                  to and use of the Web Site, superseding any prior written or
                  oral agreements in relation to the same subject matter herein
                </span>
              </li>
            </ul>
          </div>
        </section>
      </DefaultLayout>
    </>
  );
}

export default TermsConditions;
