import { FaSearch, FaTable } from "react-icons/fa";
import {
  Button,
  Card,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { doListingShortlist, fetchListings } from "../../services/meta";
import { useEffect, useRef, useState } from "react";
import { decodeJWT } from "../../helpers/authHelper";
import toast from "react-hot-toast";
import styled from "styled-components";
import { FaTableCells, FaTableCellsLarge } from "react-icons/fa6";
import ListingListView from "../../components/Listings/ListingListView";
import ListingGridView from "../../components/Listings/ListingGridView";
import DefaultLayout from "../../components/DefaultLayout";
import PaginationComponent from "../../components/PaginationComponent";
import CategorySubCategorySelect from "../../components/CategorySubCategorySelect";
import CitySelect from "../../components/Inputs/CitySelect";
import { useLocation, useNavigate } from "react-router-dom";
const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin: 16px 0;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #fff;
  border-bottom: 1px solid #e9ecef;
  @media (min-width: 576px) {
    flex-direction: row;
    align-items: center;
    padding: 16px;
  }
`;

const TotalRecords = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #495057;
  @media (min-width: 576px) {
    margin-top: 0;
    margin-left: 16px;
  }
`;

const ViewToggle = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  .icon {
    cursor: pointer;
    font-size: 24px;
    margin-left: 16px;
  }
  @media (min-width: 576px) {
    margin-left: auto;
    margin-top: 0;
  }
`;
const Listings = ({ OnOpenLoginModal }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const searchFormRef = useRef();
  const decodedJWT = decodeJWT();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);
  const [defaultListingView, setDefaultListingView] = useState("grid");
  const [defaultSort, setDefaultSort] = useState("Created Time (DESC)");
  const searchSchema = {
    listing_type: null,
    query: queryParams.get("query"),
    category: queryParams.get("category"),
    sub_category: null,
    district: queryParams.get("location"),
    city: null,
  };
  const [listingSearchValues, setListingSearchValues] = useState(searchSchema);
  const [listings, setListings] = useState([]);
  const [pageContext, setPageContext] = useState({
    hasMorePage: false,
    page: 1,
    perPage: 10,
    sortColumn: "id",
    sortOrder: "DESC",
    totalRecords: 0,
  });
  const _updateSortOrder = (sortColumn, sortOrder) => {
    const filteredKeys = {};
    Object.entries(listingSearchValues).filter((v) => {
      if (v[1] !== null) {
        filteredKeys[v[0]] = v[1];
      }
    });
    _fetchListings({
      ...filteredKeys,
      page: 1,
      per_page: pageContext.perPage,
      sort_column: sortColumn,
      sort_order: sortOrder,
    });
    setDefaultSort(`${sortColumn}_${sortOrder}`);
  };
  const _fetchListings = async (params) => {
    setListings([]);
    await fetchListings(params)
      .then((response) => {
        const { data } = response;
        if (data.data.listings) {
          setListings(data.data.listings);
          const _pageContext = data.data.page_context;
          setPageContext({
            hasMorePage: _pageContext.has_more_page,
            page: _pageContext.page,
            perPage: _pageContext.per_page,
            sortColumn: _pageContext.sort_column,
            sortOrder: _pageContext.sort_order,
            totalRecords: _pageContext.total,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const resetSearch = () => {
    _fetchListings({
      page: 1,
      per_page: 10,
      sort_column: "id",
      sort_order: "DESC",
    });
  };
  const filterListing = (
    page = null,
    per_page = null,
    sort_column = null,
    sort_order = null
  ) => {
    const filteredKeys = {};
    Object.entries(listingSearchValues).filter((v) => {
      if (v[1] !== null) {
        filteredKeys[v[0]] = v[1];
      }
    });
    if (searchFormRef && searchFormRef.current) {
      if (searchFormRef.current.category_id.value) {
        filteredKeys["category_id"] = searchFormRef.current.category_id.value;
      }
      if (searchFormRef.current.sub_category_id.value) {
        filteredKeys["sub_category_id"] =
          searchFormRef.current.sub_category_id.value;
      }
      if (searchFormRef.current.city_id.value) {
        filteredKeys["city_id"] = searchFormRef.current.city_id.value;
      }
    }
    _fetchListings({
      ...filteredKeys,
      page: page ? page : 1,
      per_page: per_page ? per_page : pageContext.perPage,
      sort_column: sort_column ? sort_column : pageContext.sortColumn,
      sort_order: sort_order ? sort_order : pageContext.sortOrder,
    });
  };
  const _doListingShortlist = async (listingId, flag) => {
    if (decodedJWT) {
      await doListingShortlist({
        listing_id: listingId,
        flag,
      })
        .then((response) => {
          toast.success(response.data.message);
        })
        .catch((err) => {
          // toast.error(err.response.data.message);
        });
      return flag ? true : false;
    } else {
      OnOpenLoginModal();
      return false;
    }
  };
  useEffect(() => {
    _fetchListings({
      sort_order: "DESC",
      query: queryParams.get("query"),
      district: queryParams.get("location"),
      category_id: queryParams.get("category"),
    });
  }, []);
  return (
    <DefaultLayout>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Advanced Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={searchFormRef}>
            <Row>
              <Form.Label className="form-label col-form-label col-md-3">
                Listing Type
              </Form.Label>
              <div className="col-md-9 gy-2">
                <Form.Check
                  inline
                  type="radio"
                  name="listing_type"
                  onChange={(e) =>
                    setListingSearchValues({
                      ...listingSearchValues,
                      listing_type: e.target.value,
                    })
                  }
                  value={"S"}
                  label="Sale"
                  checked={
                    listingSearchValues.listing_type === "S" ? true : false
                  }
                />
                <Form.Check
                  inline
                  type="radio"
                  name="listing_type"
                  onChange={(e) =>
                    setListingSearchValues({
                      ...listingSearchValues,
                      listing_type: e.target.value,
                    })
                  }
                  value={"R"}
                  label="Rent"
                  checked={
                    listingSearchValues.listing_type === "R" ? true : false
                  }
                />
                <Form.Check
                  inline
                  type="radio"
                  name="listing_type"
                  onChange={(e) =>
                    setListingSearchValues({
                      ...listingSearchValues,
                      listing_type: e.target.value,
                    })
                  }
                  value={"SR"}
                  label="Service"
                  checked={
                    listingSearchValues.listing_type === "SR" ? true : false
                  }
                />
              </div>
            </Row>
            <CategorySubCategorySelect
              defaultCategory={listingSearchValues.category}
              defaultSubCategory={listingSearchValues.sub_category}
            />
            <Row className="mt-3">
              <Form.Label className="form-label col-form-label col-md-3">
                City
              </Form.Label>
              <div className="col-md-9 gy-2">
                <CitySelect defaultValue={listingSearchValues.city} />
              </div>
            </Row>
            <Row className="mt-3">
              <Form.Label className="form-label col-form-label col-md-3"></Form.Label>
              <div className="col-md-9 gy-2">
                <Button
                  onClick={() => {
                    filterListing();
                    handleClose();
                  }}
                  size="sm"
                  variant="primary"
                >
                  Filter
                </Button>
                <Button
                  onClick={(e) => {
                    setListingSearchValues({ ...searchSchema });
                    resetSearch();
                    handleClose();
                    navigate("/search");
                  }}
                  variant="secondary"
                  size="sm"
                  className="mx-2"
                >
                  Reset
                </Button>
              </div>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <div as="section" className="container content-overlay mb-md-4 pt-5 mt-2">
        <CardContainer>
          <Header>
            <DropdownButton
              title={`Sort By : ${defaultSort}`}
              variant="secondary"
              className="mt-0"
              size="sm"
            >
              {[
                {
                  id: "title_desc",
                  title: "Title (DESC)",
                  sortOrder: "DESC",
                  sortColumn: "title",
                },
                {
                  id: "title_asc",
                  title: "Title (ASC)",
                  sortOrder: "ASC",
                  sortColumn: "title",
                },
                {
                  id: "price_desc",
                  title: "Price (DESC)",
                  sortOrder: "DESC",
                  sortColumn: "price",
                },
                {
                  id: "price_asc",
                  title: "Price (ASC)",
                  sortOrder: "ASC",
                  sortColumn: "price",
                },
                {
                  id: "created_time_desc",
                  title: "Created Time (DESC)",
                  sortOrder: "DESC",
                  sortColumn: "created_time",
                },
                {
                  id: "created_time_asc",
                  title: "Created Time (ASC)",
                  sortOrder: "ASC",
                  sortColumn: "created_time",
                },
              ].map((e) => {
                return (
                  <Dropdown.Item
                    key={e.id}
                    onClick={() => {
                      _updateSortOrder(e.sortColumn, e.sortOrder);
                      setDefaultSort(e.title);
                    }}
                    active={e.id === `${e.sortColumn}_${e.sortOrder}`}
                  >
                    {e.title}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
            <TotalRecords className="mx-2">
              Total Listings: {pageContext.totalRecords}
            </TotalRecords>
            <ViewToggle active={defaultListingView === "list"}>
              <Button
                className="btn btn-sm btn-secondary rounded-pill"
                onClick={() => handleShow()}
              >
                <FaSearch className="me-2" />
                Advanced Search
              </Button>
              <FaTableCells
                className={`icon ${
                  defaultListingView !== "list" ? "text-success" : ""
                }`}
                onClick={() => setDefaultListingView("grid")}
              />
              <FaTableCellsLarge
                className={`icon ${
                  defaultListingView === "list" ? "text-success" : ""
                }`}
                onClick={() => setDefaultListingView("list")}
              />
            </ViewToggle>
          </Header>
        </CardContainer>
        {listings.length ? (
          <>
            {defaultListingView === "list" ? (
              <Row xs={1} sm={2} md={2} className="mx-0">
                {listings.map((e) => {
                  return (
                    <ListingListView
                      listing={e}
                      OnDoListingShortlist={_doListingShortlist}
                    />
                  );
                })}
              </Row>
            ) : (
              <Row xs={1} sm={2} md={4} className="mx-0">
                {listings.map((e) => {
                  return (
                    <ListingGridView
                      listing={e}
                      OnDoListingShortlist={_doListingShortlist}
                    />
                  );
                })}
              </Row>
            )}
            <div className="mt-2">
              <PaginationComponent
                pageContext={pageContext}
                OnFilter={filterListing}
              />
            </div>
          </>
        ) : (
          <>
            <div className="text-center pt-2 pt-md-4 pt-lg-5 pb-2 pb-md-0">
              <i className="fi-heart display-6 text-muted mb-4"></i>
              <h2 className="h5 mb-2">
                {loading
                  ? "Loading..."
                  : " Listing isn't available as per your search!"}
              </h2>
            </div>
            <div className="row my-5 pb-5">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <Card border="0" className="shadow">
                  <Card.Body>
                    <Card.Title as="h5">Tell us what you need</Card.Title>
                    <Card.Text>
                      Post your requirement to get the best deals from our
                      verified sellers.
                    </Card.Text>
                    <Button
                      onClick={() => navigate("/?show_req=true")}
                      className="btn btn-primary w-100 rounded-pill"
                    >
                      Post Your Requirement
                    </Button>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-md-4"></div>
            </div>
          </>
        )}
      </div>
    </DefaultLayout>
  );
};

export default Listings;
