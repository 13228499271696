import { Button, Col } from "react-bootstrap";
import LimitedText from "../LimitedText";
import { FaLocationDot, FaLocationPin } from "react-icons/fa6";
import { FaMobileAlt, FaTag } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { IoShieldCheckmark, IoTimeOutline } from "react-icons/io5";
import { GrOrganization } from "react-icons/gr";

const ListingGridView = ({ listing, OnDoListingShortlist }) => {
  const [isShortlisted, setIsShortListed] = useState(listing.is_shortlisted);
  return (
    <Col className="p-2">
      <div className={"card card-hover"}>
        <div
          className="card-img-top card-img-hover d-flex"
          style={{
            padding: "10px",
          }}
        >
          {listing.formatted_primary_image_document_file ? (
            <img
              alt="Image"
              light="0"
              src={listing.formatted_primary_image_document_file}
              style={{
                boxSizing: "border-box",
                padding: "0px",
                border: "none",
                margin: "auto",
                display: "block",
                width: "175px",
                height: "175px",
                minWidth: "100%",
                maxWidth: "100%",
                minHeight: "100%",
                maxHeight: "100%",
                objectFit: "cover",
              }}
            />
          ) : (
            <div
              style={{
                boxSizing: "border-box",
                padding: "0px",
                border: "none",
                margin: "auto",
                display: "block",
                width: "175px",
                height: "175px",
                minWidth: "100%",
                maxWidth: "100%",
                minHeight: "100%",
                maxHeight: "100%",
                objectFit: "cover",
              }}
            ></div>
          )}

          <span className="img-overlay"></span>
          <div className="position-absolute start-0 top-0 pt-3 ps-3">
            <span className="d-table badge custom-badge fs-xs text-uppercase mb-1">
              For {listing.formatted_listing_type}
            </span>
          </div>
          <div
            className="position-absolute end-0 top-0 pt-3 ps-3"
            style={{ paddingRight: "15px" }}
          >
            {listing.listing_type === "S" ? (
              <span
                className={`d-table badge bg-${
                  listing.available_stock ? "success" : "warning"
                } mb-1`}
              >
                {listing.available_stock ? "In Stock" : "Out of Stock"}
              </span>
            ) : null}
          </div>
        </div>
        <div className="card-body position-relative pb-3">
          <div className="mb-1">
            <span
              style={{
                fontSize: "0.75rem",
                fontWeight: "normal",
                marginRight: "2px",
              }}
            >
              <IoTimeOutline /> {listing.formatted_posted_ago}
            </span>
            {listing.is_premium ? (
              <span className={`premium-badge mb-1 mx-2`}>
                <IoShieldCheckmark />
              </span>
            ) : null}
          </div>
          <div className="row">
            <div className="col-md-12">
              <h3
                className="h6 mb-2"
                style={{
                  display: "block",
                  border: "3px solid #f2f2f2",
                  padding: "5px",
                  borderRadius: "0.75rem",
                  fontSize: "0.95rem",
                }}
              >
                <LimitedText text={listing.title} limit="25" />
              </h3>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-7"
              style={{ paddingLeft: "2px", paddingRight: "2px" }}
            >
              <span
                className="btn btn-outline-secondary btn-xs w-100"
                style={{ padding: "10px" }}
              >
                <GrOrganization className="me-2" />{" "}
                {listing["user"] && listing["user"]["organization_name"] ? (
                  <LimitedText
                    text={listing["user"]["organization_name"]}
                    limit={"20"}
                  />
                ) : (
                  "NA"
                )}
              </span>
            </div>
            <div
              className="col-md-5"
              style={{ paddingLeft: "2px", paddingRight: "2px" }}
            >
              <span
                className="btn btn-outline-secondary btn-xs w-100"
                style={{ padding: "10px" }}
              >
                <FaLocationDot className="me-2" />
                {listing["city"] ? listing["city"]["name"] : "NA"}
              </span>
            </div>
          </div>
          <h3 className="h4 mb-2 mt-3">
            {listing.formatted_display_price}/
            <span style={{ fontSize: "12px" }}>
              {listing["unit"] ? listing["unit"]["short_name"] : null}
            </span>
            {listing.formatted_display_price !==
            listing.formatted_sales_price ? (
              <span
                className="mx-2 px-1 btn border-0 btn-outline-secondary btn-sm my-0 py-0"
                style={{ fontWeight: "normal" }}
              >
                <FaTag />
                <strike className="mx-1">
                  {listing.formatted_sales_price}
                </strike>
              </span>
            ) : null}
          </h3>
          <div className="row">
            <div className="col-sm-10" style={{ paddingRight: "0px" }}>
              <Button
                onClick={() =>
                  window.open("/listing/" + listing.slug, "_blank")
                }
                className="w-100 px-0"
              >
                <FaMobileAlt className="me-2" /> Contact Seller
              </Button>
            </div>
            <div className="col-sm-2" style={{ paddingLeft: "0px" }}>
              <Button
                className={`btn btn-icon w-100 px-0 ${
                  isShortlisted ? "btn-success" : "btn-secondary"
                } mx-2`}
                onClick={async () => {
                  const _isShortlisted = isShortlisted
                    ? await OnDoListingShortlist(listing.id, 0)
                    : await OnDoListingShortlist(listing.id, 1);
                  setIsShortListed(_isShortlisted);
                }}
              >
                <i className={`fi-heart${isShortlisted ? "-filled" : ""}`}></i>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ListingGridView;
