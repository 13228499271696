import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  Breadcrumb,
  FormControl,
} from "react-bootstrap";
import toast from "react-hot-toast";
import DefaultLayout from "../../components/DefaultLayout";
import { FaUserCircle } from "react-icons/fa";
import { fetchProfile, updateProfile } from "../../services/meta";
function EditProfile() {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({});
  const _fetchProfile = async () => {
    await fetchProfile()
      .then((response) => {
        const { data } = response;
        setProfileData(data.data.user);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    await updateProfile({
      first_name: e.target.first_name.value,
      last_name: e.target.last_name.value,
      alternative_number: e.target.alternative_number.value,
      email: e.target.email.value,
      portal_from: "buyer",
    })
      .then((response) => {
        toast.success(response.data.message);
        navigate("/profile");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  useEffect(() => {
    _fetchProfile();
  }, []);
  return (
    <>
      <DefaultLayout>
        <div
          className="position-absolute top-0 start-0 w-100 bg-dark"
          style={{ height: "398px" }}
        ></div>
        <Container as="section" className="content-overlay mb-md-4 py-5 mt-3">
          <Breadcrumb className="breadcrumb-light mb-3 mb-md-4 pt-md-3">
            <Breadcrumb.Item linkAs={Link} onClick={() => navigate("/")}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} onClick={() => navigate("/profile")}>Profile</Breadcrumb.Item>
            <Breadcrumb.Item active>Setup</Breadcrumb.Item>
          </Breadcrumb>
          <div className="bg-light shadow-sm rounded-3 p-4 p-md-5 mb-2">
            <div className="d-flex align-items-start justify-content-between pb-4 mb-2">
              <div className="d-flex align-items-start">
                <div className="position-relative flex-shrink-0">
                  <FaUserCircle size={"75px"} />
                </div>
                <div className="ps-3 ps-sm-4">
                  <h3 className="h5">
                    {profileData && profileData.formatted_username}
                  </h3>
                  <ul className="list-unstyled fs-sm mb-0">
                    {profileData && profileData.email ? (
                      <li className="d-flex text-nav text-break">
                        <i className="fi-mail opacity-60 mt-1 me-2"></i>
                        <span>{profileData && profileData.email}</span>
                      </li>
                    ) : null}
                    <li className="d-flex text-nav text-break">
                      <i className="fi-phone opacity-60 mt-1 me-2"></i>
                      <span>
                        (+91) {profileData && profileData.mobile_number}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <Form onSubmit={onSubmit}>
              <Row className="pt-4 mt-3">
                <Col xs={12} lg={3}>
                  <h2 className="h4">Basic Details</h2>
                </Col>
                <Col xs={12} lg={9}>
                  <div className="border rounded-3 p-3">
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="" className="form-label fw-bold">
                          First Name
                        </label>
                        <FormControl
                          type="text"
                          className="mb-3"
                          placeholder="Enter your first name"
                          name="first_name"
                          defaultValue={profileData && profileData.first_name}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="" className="form-label fw-bold">
                          Last Name
                        </label>
                        <FormControl
                          type="text"
                          placeholder="Enter your Last Name"
                          className="mb-3"
                          name="last_name"
                          defaultValue={profileData && profileData.last_name}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="" className="form-label fw-bold">
                          Mobile Number
                        </label>
                        <FormControl
                          type="text"
                          className="mb-3"
                          defaultValue={
                            profileData && profileData.mobile_number
                          }
                          readOnly
                          disabled
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="" className="form-label fw-bold">
                          Email
                        </label>
                        <FormControl
                          type="email"
                          placeholder="Enter your email address"
                          defaultValue={profileData && profileData.email}
                          name="email"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="" className="form-label fw-bold">
                          Alternative Number
                        </label>
                        <FormControl
                          type="text"
                          name="alternative_number"
                          className="mb-3"
                          defaultValue={
                            profileData && profileData.alternative_number
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12} lg={3}></Col>
                <Col xs={12} lg={9}>
                  <Button
                    type="submit"
                    className="btn btn-primary rounded-pill"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Container>
      </DefaultLayout>
    </>
  );
}

export default EditProfile;
