import DefaultLayout from "../components/DefaultLayout";
function AboutUs() {
  return (
    <>
      <DefaultLayout>
        <section className="bg-secondary py-5 pb-0">
          <div className="py-5 pb-0 container">
            <nav aria-label="breadcrumb" className="pt-3 mb-4">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  About Us
                </li>
              </ol>
            </nav>
            <div className="px-0 col-lg-8 col-md-10">
              <h1 className="pb-2">About Us</h1>
            </div>
          </div>
        </section>
        <section className="position-relative bg-white rounded-xxl-4 mb-5 py-2 zindex-5 container">
          <div className="p-5">
            <div>
              <p>
                Blandit adipiscing duis sit tellus rhoncus, amet, sit vitae
                gravida. Tincidunt placerat ultrices eu, senectus vitae accumsan
                massa in. Ultricies imperdiet duis feugiat lorem. Pretium turpis
                faucibus sit urna nisi lorem interdum. Diam semper et ac neque
                ac.
                <br />
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut
                optio incidunt excepturi praesentium quaerat natus voluptate
                error? Voluptatem qui, obcaecati exercitationem hic unde ratione
                animi distinctio pariatur perferendis dicta eligendi. Eligendi
                reiciendis dicta dignissimos laborum labore fugiat magnam
                dolores! Itaque illum molestias deserunt saepe nulla tempora.
                Libero voluptatum distinctio reprehenderit, tenetur quidem
                molestiae id corporis aut ullam obcaecati vero repellendus.
                Neque obcaecati aut sint corrupti ab, enim, accusamus ipsa
                accusantium officia eius maxime ea. Soluta debitis modi, aliquam
                quas vero quae! Provident aut accusamus nostrum voluptate
                facilis! Labore, non asperiores! Sequi minima autem perferendis
                voluptatem a ducimus consequatur nam ipsum quaerat, quo expedita
                veniam eaque sunt odio beatae fugiat dolores voluptate illo!
                Recusandae aliquid officia, repellendus nobis natus eaque vel.
              </p>
            </div>
          </div>
        </section>
      </DefaultLayout>
    </>
  );
}

export default AboutUs;
