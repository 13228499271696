import DefaultLayout from "../components/DefaultLayout";
function RefundPolicy() {
  return (
    <>
      <DefaultLayout>
        <section className="bg-secondary py-5 pb-0">
          <div className="py-5 pb-0 container">
            <nav aria-label="breadcrumb" className="pt-3 mb-4">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Refund Policy
                </li>
              </ol>
            </nav>
            <div className="px-0 col-lg-8 col-md-10">
              <h1 className="pb-2">Refund Policy</h1>
            </div>
          </div>
        </section>
        <section className="position-relative bg-white rounded-xxl-4 mb-5 py-2 zindex-5 container">
          <div className="p-5">
            <div>
              <h3 className="h5">
                Lorem ipsum, dolo reiciendis voluptatem perspiciatis.?
              </h3>
              <p>
                Blandit adipiscing duis sit tellus rhoncus, amet, sit vitae
                gravida. Tincidunt placerat ultrices eu, senectus vitae accumsan
                massa in. Ultricies imperdiet duis feugiat lorem. Pretium turpis
                faucibus sit urna nisi lorem interdum. Diam semper et ac neque
                ac.
              </p>
            </div>
            <div>
              <h3 className="h5">
                Lorem molestiae. Nesciunt eaque ex reiciendis voluptatem
                perspiciatis.?
              </h3>
              <p>
                Blandit adipiscing duis sit tellus rhoncus, amet, sit vitae
                gravida. Tincidunt placerat ultrices eu, senectus vitae accumsan
                massa in. Ultricies imperdiet duis feugiat lorem. Pretium turpis
                faucibus sit urna nisi lorem interdum. Diam semper et ac neque
                ac.
              </p>
            </div>
            <div>
              <h3 className="h5">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                perspiciatis.?
              </h3>
              <p>
                Blandit adipiscing duis sit tellus rhoncus, amet, sit vitae
                gravida. Tincidunt placerat ultrices eu, senectus vitae accumsan
                massa in. Ultricies imperdiet duis feugiat lorem. Pretium turpis
                faucibus sit urna nisi lorem interdum. Diam semper et ac neque
                ac.
              </p>
            </div>
          </div>
        </section>
      </DefaultLayout>
    </>
  );
}

export default RefundPolicy;
