import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Form, Row } from "react-bootstrap";
import { fetchCategoryLists, fetchSubCategoryLists } from "../../services/meta";
const CategorySubCategorySelect = ({ defaultCategory, defaultSubCategory }) => {
  const [categories, setCategories] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const _fetchCategoryLists = async () => {
    await fetchCategoryLists()
      .then((response) => {
        const { data } = response;
        if (data.data.categories) {
          const _options = [];
          data.data.categories.forEach((v) => {
            _options.push({
              value: v["id"],
              label: v["name"],
            });
          });
          setCategories(_options);
        }
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const _fetchSubCategoryListsByCategory = async (selectedCategory) => {
    await fetchSubCategoryLists(selectedCategory)
      .then((response) => {
        const { data } = response;
        if (data.data.sub_categories) {
          const _options = [];
          data.data.sub_categories.forEach((v) => {
            _options.push({
              value: v["id"],
              label: v["name"],
            });
          });
          setSubCategoryOptions(_options);
        }
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  useEffect(() => {
    _fetchCategoryLists();
  }, []);
  useEffect(() => {
    if (defaultCategory) {
      const categoryOption = categories.find(
        (category) => category.value === defaultCategory
      );
      setSelectedCategory(categoryOption);
      _fetchSubCategoryListsByCategory(defaultCategory);
    }
  }, [defaultCategory]);

  useEffect(() => {
    if (defaultSubCategory) {
      const subCategoryOption = subCategoryOptions.find(
        (subCategory) => subCategory.value === defaultSubCategory
      );
      setSelectedSubCategory(subCategoryOption);
      setSelectedSubCategory(subCategoryOption);
    }
  }, [selectedCategory, subCategoryOptions, defaultSubCategory]);

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
    _fetchSubCategoryListsByCategory(selectedOption.value);
    setSelectedSubCategory(null);
  };

  const handleSubCategoryChange = (selectedOption) => {
    setSelectedSubCategory(selectedOption);
  };

  return (
    <>
      <Row className="mt-3">
        <Form.Label className="form-label col-form-label col-md-3">
          Category <span className="text-danger">&nbsp;*</span>
        </Form.Label>
        <div className="col-md-9 gy-2">
          <Select
            isClearable={true}
            value={selectedCategory}
            onChange={handleCategoryChange}
            options={categories}
            name="category_id"
            placeholder="Select a Category"
            isMulti={false}
          />
        </div>
      </Row>
      <Row className="mt-3 mb-3">
        <Form.Label className="form-label col-form-label col-md-3">
          Sub-Category <span className="text-danger">&nbsp;*</span>
        </Form.Label>
        <div className="col-md-9 gy-2">
          <Select
            isClearable={true}
            value={selectedSubCategory}
            onChange={handleSubCategoryChange}
            options={subCategoryOptions}
            placeholder="Select a SubCategory"
            name="sub_category_id"
            isDisabled={!selectedCategory}
            isMulti={false}
          />
        </div>
      </Row>
    </>
  );
};

export default CategorySubCategorySelect;
