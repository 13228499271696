import {
  Navbar,
  Container,
  Nav,
  Dropdown,
  Button,
  InputGroup,
  FormControl,
  FormSelect,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import StickyNavbar from "../StickyNavbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import farmWayLogo from "../../images/logo.svg";
import { FaPlus, FaRegUserCircle, FaSearch } from "react-icons/fa";
import { useEffect, useState } from "react";
import { fetchStateWiseDistricts } from "../../services/meta";
import { MdChecklist } from "react-icons/md";
import ReactSelect from "react-select";
import { FaEllipsisVertical } from "react-icons/fa6";
function AppNavBar(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchOpen, setSearchOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [navOpen, setNavOpen] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const [stateDistricts, setStateDistricts] = useState([]);
  const [formData, setFormData] = useState({
    searchQuery: "",
    searchLocation: "",
  });
  const _fetchDistrictsByState = async () => {
    await fetchStateWiseDistricts()
      .then((response) => {
        const { data } = response;
        setStateDistricts(data.data.state_districts || {});
      })
      .catch((err) => {
        // console.log(err.response.data);
      });
  };
  useEffect(() => {
    _fetchDistrictsByState();
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const searchListing = () => {
    const params = new URLSearchParams(window.location.search);
    if (formData.searchQuery || queryParams.get("query")) {
      params.set("query", formData.searchQuery || queryParams.get("query"));
    } else {
      params.delete("query");
    }
    if (formData.searchLocation) {
      params.set("location", formData.searchLocation);
    } else {
      params.delete("location");
    }
    console.log(`/search?${params.toString()}`);
    window.location.href = `/search?${params.toString()}`;
  };
  return (
    <>
      <Navbar
        as={StickyNavbar}
        bg="light"
        expand="md"
        className={`fixed-top py-2`}
        style={{
          background: "#fff0db !important",
        }}
      >
        <Container>
          <Navbar.Brand
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
            className="flex-shrink-0 me-2 me-xl-4"
          >
            <img src={farmWayLogo} width={120} alt="FARMWAY" />
          </Navbar.Brand>
          {/* Search visible on desktop */}
          <InputGroup
            size="sm"
            className="d-none d-md-flex ps-2 order-lg-3 mx-5"
          >
            <ReactSelect
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: "200px",
                }),
              }}
              options={stateDistricts}
              placeholder="All Location"
              onChange={(e) => {
                setFormData({ ...formData, searchLocation: e.value });
              }}
            />
            <FormControl
              type="search"
              placeholder="Search ..."
              defaultValue={queryParams.get("query")}
              onChange={(e) => {
                setFormData({ ...formData, searchQuery: e.target.value });
              }}
              style={{
                background: "#f5f4f8",
              }}
            />
            <Button
              className="btn btn-secondary"
              onClick={() => searchListing()}
            >
              <FaSearch />
            </Button>
          </InputGroup>
          {isMobile ? (
            <Button
              onClick={() =>
                (window.location.href = "https://seller.farmway.in/signup")
              }
              className="rounded-pill order-lg-3 btn-sm"
            >
              <FaPlus className="me-1" style={{ color: "white" }} /> SELL
            </Button>
          ) : (
            <Button
              onClick={() =>
                (window.location.href = "https://seller.farmway.in/signup")
              }
              className="rounded-pill order-lg-3 btn-sm"
            >
              <FaPlus
                className="me-1 py-0 my-0"
                size={"32"}
                style={{ color: "white", height: "20px" }}
              />{" "}
              SELL
            </Button>
          )}

          {props.userLoggedIn ? (
            <Dropdown className="d-none d-lg-block order-lg-3 my-n2 me-1">
              <Dropdown.Toggle
                as={Link}
                className="nav-link dropdown-toggle-flush d-flex py-1 px-0"
                style={{ width: "40px", marginLeft: "15px" }}
              >
                <FaRegUserCircle size={32} />
              </Dropdown.Toggle>
              <Dropdown.Menu variant="dark" renderOnMount align="end">
                <div
                  className="d-flex align-items-start border-bottom border-light px-3 py-1 mb-2"
                  style={{ width: "16rem" }}
                >
                  <div className="ps-2">
                    <h6 className="fs-base mb-0 text-light">
                      Hi, {props.userName}
                    </h6>
                  </div>
                </div>
                <Dropdown.Item
                  onClick={() => {
                    navigate("/profile");
                  }}
                >
                  <i className="fi-user me-2"></i>
                  My Profile
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    navigate("/shortlists");
                  }}
                >
                  <MdChecklist className="me-2" />
                  My Shortlists
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    localStorage.removeItem("bam-token");
                    window.location.href = "/";
                  }}
                >
                  <i className="fi-logout me-2"></i>
                  Sign Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <>
              <Button
                size="sm"
                variant="link d-none d-lg-block order-lg-4"
                onClick={() => props.OnOpenLoginModal()}
              >
                <i className="fi-user me-2"></i>
                Sign in
              </Button>
            </>
          )}
          <Navbar.Text className="d-lg-none">
            <FaSearch
              size={"20"}
              onClick={() => {
                setSearchOpen(!searchOpen);
                setNavOpen(false);
              }}
            />
          </Navbar.Text>
          <Navbar.Text className="d-lg-none">
            <FaRegUserCircle
              size={"24"}
              onClick={() => {
                setNavOpen(!navOpen);
                setSearchOpen(false);
              }}
            />
          </Navbar.Text>
          <Dropdown className="order-lg-5 my-n2">
            <Dropdown.Toggle
              as={Link}
              className="nav-link dropdown-toggle-flush d-flex py-1"
            >
              <FaEllipsisVertical size={24} />
            </Dropdown.Toggle>
            <Dropdown.Menu renderOnMount align="end">
              <Dropdown.Item
                onClick={() => {
                  navigate("/about-us");
                }}
              >
                About Us
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  navigate("/privacy-policy");
                }}
              >
                Privacy Policy
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  navigate("/terms-conditions");
                }}
              >
                Terms and Conditions
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  navigate("/refund-policy");
                }}
              >
                Refund Policy
              </Dropdown.Item>
              {/* <Dropdown.Item
                onClick={() => {
                  navigate("/help");
                }}
              >
                Help
              </Dropdown.Item> */}
              <Dropdown.Item
                onClick={() => {
                  navigate("/contact-us");
                }}
              >
                Contact Us
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {searchOpen ? (
            <Nav className="d-md-none px-4 py-3 my-3 bg-light w-100 mt-1">
              <Form.Group className="mb-3">
                <Form.Label>Search</Form.Label>
                <FormControl
                  type="search"
                  placeholder="Search ..."
                  defaultValue={queryParams.get("query")}
                  onChange={(e) => {
                    setFormData({ ...formData, searchQuery: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Location</Form.Label>
                <ReactSelect
                  options={stateDistricts}
                  placeholder="All Location"
                  onChange={(e) => {
                    setFormData({ ...formData, searchLocation: e.value });
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Button
                  className="btn btn-secondary w-100"
                  onClick={() => searchListing()}
                >
                  <FaSearch className="me-2" /> Search
                </Button>
              </Form.Group>
            </Nav>
          ) : null}
          {navOpen ? (
            <Nav className="bg-light w-100 d-lg-none mt-1">
              {props.userLoggedIn ? (
                <>
                  <Nav.Item className="p-2">Hi, {props.userName}</Nav.Item>
                  <Nav.Item
                    className="p-2"
                    onClick={() => {
                      navigate("/profile");
                    }}
                  >
                    <i className="fi-user me-2"></i>
                    My Profile
                  </Nav.Item>
                  <Nav.Item
                    className="p-2"
                    onClick={() => {
                      navigate("/shortlists");
                    }}
                  >
                    <MdChecklist className="me-2" />
                    My Shortlists
                  </Nav.Item>
                  <Nav.Item
                    className="p-2"
                    onClick={() => {
                      localStorage.removeItem("bam-token");
                      window.location.href = "/";
                    }}
                  >
                    <i className="fi-logout me-2"></i>
                    Sign Out
                  </Nav.Item>
                </>
              ) : (
                <Nav.Item className="d-lg-none">
                  <Button
                    size="sm"
                    variant="link order-lg-4"
                    onClick={() => props.OnOpenLoginModal()}
                  >
                    <i className="fi-user me-2"></i>
                    Sign in
                  </Button>
                </Nav.Item>
              )}
            </Nav>
          ) : null}
        </Container>
      </Navbar>
    </>
  );
}

export default AppNavBar;
