import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import { decodeJWT, isAuthenticated } from './helpers/authHelper';
import Home from './pages/Home';
import Listings from "./pages/listings/Listings";
import Listing from "./pages/listings/Listing";
import ContactUs from "./pages/ContactUs";
import Help from "./pages/Help";
import AboutUs from "./pages/AboutUs";
import { Modal } from "react-bootstrap";
import SignIn from "./components/auth/SignIn";
import SignUp from "./components/auth/SignUp";
import AppNavBar from "./components/partials/AppNavBar";
import { useEffect, useState } from "react";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import RefundPolicy from "./pages/RefundPolicy";
import EditProfile from "./pages/profile/EditProfile";
import UserProfile from "./pages/profile/UserProfile";
import MyShortlists from "./pages/MyShortlists";
import NetworkStatus from "./components/NetworkStatus";

function App() {
  const decodedJWT = decodeJWT();
  // useEffect(() => {
  //   if (isAuthenticated() === false) {
  //     navigate('/');
  //   }
  // }, [])
  const [loginModalShow, setLoginModalShow] = useState(false);
  const [defaultModal, setDefaultModal] = useState("login");
  return (
    <BrowserRouter>
      <NetworkStatus />
      <main className="page-wrapper">
        <Modal size="lg" show={loginModalShow}>
          <Modal.Body className="p-0 m-0">
            {defaultModal === "login" ? (
              <SignIn
                OnSetDetaultModal={setDefaultModal}
                OnCloseModal={setLoginModalShow}
              />
            ) : (
              <SignUp
                OnSetDetaultModal={setDefaultModal}
                OnCloseModal={setLoginModalShow}
              />
            )}
          </Modal.Body>
        </Modal>
        <AppNavBar
          userLoggedIn={decodedJWT ? true : false}
          userName={decodedJWT ? decodedJWT.username : "Buyer"}
          OnOpenLoginModal={() => setLoginModalShow(true)}
        />
        <Routes>
          {
            isAuthenticated() ? <>
              <Route path="/profile" exact element={<UserProfile />} />
              <Route path="/profile/edit" exact element={<EditProfile />} />
              <Route path="/shortlists" exact element={<MyShortlists OnOpenLoginModal={() => setLoginModalShow(true)} />} />
            </> : null
          }

          <Route path="/" exact element={<Home OnOpenLoginModal={() => setLoginModalShow(true)} />} />
          <Route path="/search" exact element={<Listings OnOpenLoginModal={() => setLoginModalShow(true)} />} />
          <Route path="/listing/:slug" exact element={<Listing OnOpenLoginModal={() => setLoginModalShow(true)} />} />
          <Route path="/about-us" exact element={<AboutUs />} />
          <Route path="/help" exact element={<Help />} />
          <Route path="/contact-us" exact element={<ContactUs />} />
          <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" exact element={<TermsConditions />} />
          <Route path="/refund-policy" exact element={<RefundPolicy />} />
          <Route path="*" exact element={<Home />} />
        </Routes>
      </main>
    </BrowserRouter>
  );
}

export default App;
