import {
  Container,
  Button,
  Badge,
  Row,
  Col,
  Nav,
  ListGroup,
  Modal,
  Form,
  Tabs,
  Tab,
  Card,
} from "react-bootstrap";
import DefaultLayout from "../../components/DefaultLayout";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  FaAngleLeft,
  FaEdit,
  FaEnvelope,
  FaLocationArrow,
  FaMapMarkerAlt,
  FaMobile,
  FaMobileAlt,
  FaRegEnvelope,
  FaRegUser,
  FaTag,
  FaTruck,
  FaUserCheck,
} from "react-icons/fa";
import { FaListCheck, FaLocationPin } from "react-icons/fa6";
import { Swiper as SwiperComponent } from "swiper/react";
import { SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import LimitedText from "../../components/LimitedText";
import {
  contactSeller,
  createListingGuestVisit,
  createListingVisit,
  doListingShortlist,
  fetchListing,
  sendListingEnquiry,
} from "../../services/meta";
import toast from "react-hot-toast";
import { decodeJWT } from "../../helpers/authHelper";
import ListingGridView from "../../components/Listings/ListingGridView";
import { TbHomeCheck, TbTruckDelivery } from "react-icons/tb";
const SwiperGallery = ({ images, videos }) => {
  const [currentSlide, setCurrentSlide] = useState();
  const [totalSlides, setTotalSlides] = useState();
  return (
    <>
      <SwiperComponent
        modules={[Pagination, Navigation]}
        onSlideChange={(swiper) => {
          setCurrentSlide(swiper.realIndex + 1);
        }}
        onInit={(swiper) => {
          setCurrentSlide(swiper.realIndex + 1);
          setTotalSlides(swiper.slides.length - 2);
        }}
        pagination={{
          el: ".swiper-thumbnails",
          clickable: true,
        }}
        navigation
        spaceBetween={12}
        grabCursor
        centeredSlides={true}
        className="swiper-nav-onhover rounded-3"
      >
        {images &&
          images.map((e) => {
            return (
              <SwiperSlide className="d-flex" style={{ background: "white" }}>
                <img
                  src={e}
                  alt="Image"
                  className="rounded-3 centered-image"
                ></img>
              </SwiperSlide>
            );
          })}
        {videos &&
          videos.map((e) => {
            return (
              <SwiperSlide className="d-flex" style={{ background: "white" }}>
                <video
                  className="rounded-3 centered-image"
                  src={e}
                  alt="Image"
                  controls
                ></video>
              </SwiperSlide>
            );
          })}
      </SwiperComponent>
      <ul className="swiper-thumbnails"></ul>
    </>
  );
};
function Listing(props) {
  const decodedJWT = decodeJWT();
  const navigate = useNavigate();
  const params = useParams();
  const [enquiryModalShow, setEnquiryModalShow] = useState(false);
  const [mapPosition, setMapPosition] = useState({
    lat: null,
    lng: null,
  });
  const [listingData, setListingData] = useState({});
  const [similarListingsData, setSimilarListingsData] = useState([]);
  const [sellerListingsData, setSellerListingsData] = useState([]);
  const [listingImages, setListingImages] = useState([]);
  const [listingVideos, setListingVideos] = useState([]);
  const [isShortlisted, setIsShortListed] = useState(false);
  const _fetchListing = async (slug) => {
    let listing_id = null;
    await fetchListing(slug)
      .then((response) => {
        const { data } = response;
        setListingData(data.data.listing);
        if (data.data.listing.lat_lng) {
          const lat_lng = data.data.listing.lat_lng.toString().split(",");
          if (lat_lng && lat_lng.length) {
            setMapPosition({
              lat: Number(lat_lng[0]),
              lng: Number(lat_lng[1]),
            });
          }
        }
        setSimilarListingsData(data.data.similar_listings);
        setSellerListingsData(data.data.seller_listings);
        if (data.data.listing["listing_medias"]) {
          const _listingImages = [];
          data.data.listing["listing_medias"].forEach((e) => {
            _listingImages.push(e["formatted_media_document_file"]);
          });
          setListingImages(_listingImages);
        }
        if (data.data.listing.formatted_video_document_file) {
          const _listingVideos = [];
          _listingVideos.push(
            data.data.listing["formatted_video_document_file"]
          );
          setListingVideos(_listingVideos);
        }
        setIsShortListed(data.data.listing["listing_shortlists"].length);
        _doListingVisit(data.data.listing["id"]);
      })
      .catch((err) => {
        console.log(err);
      });
    return listing_id;
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    await sendListingEnquiry({
      listing_id: listingData.id,
      quantity: e.target.quantity.value,
      contact_name: e.target.contact_name.value,
      contact_number: e.target.contact_number.value,
      contact_email: e.target.contact_email.value,
    })
      .then((response) => {
        toast.success(response.data.message);
        e.target.quantity.value = "";
        e.target.contact_name.value = "";
        e.target.contact_number.value = "";
        e.target.contact_email.value = "";
        setEnquiryModalShow(false);
      })
      .catch((err) => {
        // toast.error(err.response.data.message);
      });
  };
  const _contactSeller = async () => {
    if (decodedJWT) {
      await contactSeller(listingData.id)
        .then((response) => {
          toast.success(response.data.message);
        })
        .catch((err) => {
          // toast.error(err.response.data.message);
        });
    } else {
      props.OnOpenLoginModal();
    }
  };
  const _doListingShortlist = async (flag) => {
    if (decodedJWT) {
      await doListingShortlist({
        listing_id: listingData.id,
        flag,
      })
        .then((response) => {
          toast.success(response.data.message);
        })
        .catch((err) => {
          // toast.error(err.response.data.message);
        });
    } else {
      props.OnOpenLoginModal();
    }
  };
  const _doListingVisit = async (id) => {
    if (decodedJWT) {
      await createListingVisit(id)
        .then((response) => {})
        .catch((err) => {});
    } else {
      await createListingGuestVisit(id)
        .then((response) => {})
        .catch((err) => {});
    }
  };
  useEffect(() => {
    if (params && params.slug) {
      _fetchListing(params.slug);
    }
  }, [params.slug]);
  return (
    <DefaultLayout>
      <Modal
        size="lg"
        show={enquiryModalShow}
        onHide={() => setEnquiryModalShow(false)}
      >
        <Modal.Header style={{ background: "#161e5f" }} closeButton>
          <Modal.Title as="h5" style={{ color: "white" }}>
            Send Enquiry
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            <Row>
              <Form.Label className="form-label col-form-label col-md-3">
                Looking for
                <span className="text-danger">&nbsp;*</span>
              </Form.Label>
              <div className="col-md-9 gy-2">
                <Form.Control
                  type="text"
                  name="title"
                  placeholder="title"
                  readOnly
                  value={listingData && listingData.title}
                />
              </div>
            </Row>
            <Row className="mt-3">
              <Form.Label className="form-label col-form-label col-md-3">
                Quantity (
                {listingData &&
                  listingData["unit"] &&
                  listingData["unit"]["short_name"]}
                )
              </Form.Label>
              <div className="col-md-9 gy-2">
                <Form.Control required type="number" name="quantity" />
              </div>
            </Row>
            <Row className="mt-3">
              <Form.Label className="form-label col-form-label col-md-3">
                Contact Name <span className="text-danger">&nbsp;*</span>
              </Form.Label>
              <div className="col-md-9 gy-2">
                <Form.Control
                  required
                  type="text"
                  name="contact_name"
                  placeholder="Contact Name"
                />
              </div>
            </Row>
            <Row className="mt-3">
              <Form.Label className="form-label col-form-label col-md-3">
                Contact Number <span className="text-danger">&nbsp;*</span>
              </Form.Label>
              <div className="col-md-9 gy-2">
                <Form.Control
                  required
                  type="text"
                  name="contact_number"
                  placeholder="Contact Number"
                />
              </div>
            </Row>
            <Row className="mt-3">
              <Form.Label className="form-label col-form-label col-md-3">
                Contact Email <span className="text-danger">&nbsp;*</span>
              </Form.Label>
              <div className="col-md-9 gy-2">
                <Form.Control
                  required
                  type="text"
                  name="contact_email"
                  placeholder="Contact Email"
                />
              </div>
            </Row>
            <Row className="mt-3">
              <Form.Label className="form-label col-form-label col-md-3"></Form.Label>
              <div className="col-md-9 gy-2">
                <Button type="submit" className="btn btn-primary rounded-pill">
                  Submit
                </Button>
                <Button
                  onClick={() => setEnquiryModalShow(false)}
                  className="btn btn-secondary mx-2 rounded-pill"
                >
                  Cancel
                </Button>
              </div>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <br />
      <section
        className="pb-1 container py-3 pb-0 mt-5"
        style={{ background: "white" }}
      >
        {listingData ? (
          <Row>
            <Col lg={6} className="pt-lg-2 mb-5 mb-lg-0">
              <div className="mb-1 badge custom-badge fs-xs text-uppercase">
                For {listingData.formatted_listing_type}
              </div>
              <div className="d-flex flex-column">
                <div
                  className="order-lg-1 order-2 p-3"
                  style={{
                    border: "1px solid #ccc",
                  }}
                >
                  <SwiperGallery
                    images={listingImages}
                    videos={listingVideos}
                  />
                </div>
              </div>
            </Col>
            <Col lg={6} className="pt-lg-2 mb-lg-0">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    {listingData.category?.name}
                  </li>
                  <li className="breadcrumb-item">
                    {listingData.sub_category?.name}
                  </li>
                </ol>
              </nav>
              <h1 className="h4 mb-2 font-weight-normal">
                {listingData.title}
                <br />
              </h1>
              {listingData["city"] ? (
                <p className="mb-2 pb-1 fs-lg">
                  <FaMapMarkerAlt className="me-1" />
                  {listingData["city"]["name"]}
                  {mapPosition.lat && mapPosition.lng ? (
                    <Button
                      onClick={() =>
                        window.open(
                          `https://www.google.com/maps?q=${mapPosition.lat},${mapPosition.lng}`,
                          "_blank"
                        )
                      }
                      style={{ cursor: "pointer" }}
                      variant="info"
                      size="sm"
                      className="mx-2"
                    >
                      <FaLocationArrow className="me-1" />
                      View Direction
                    </Button>
                  ) : null}
                </p>
              ) : null}
              <h1 className="h2 mb-4 mt-3">
                {listingData && listingData.formatted_display_price}/
                <span style={{ fontSize: "16px" }}>
                  {listingData &&
                    listingData["unit"] &&
                    listingData["unit"]["short_name"]}
                </span>
                {listingData.formatted_display_price !==
                listingData.formatted_sales_price ? (
                  <span
                    className="mx-2 btn border-0 btn-outline-secondary btn-sm"
                    style={{ fontWeight: "normal" }}
                  >
                    <FaTag />
                    <strike className="mx-2">
                      {listingData.formatted_sales_price}
                    </strike>
                  </span>
                ) : null}
              </h1>
              {listingData["listing_tags"]
                ? listingData["listing_tags"].map((t) => {
                    return (
                      <Badge className="m-1 bg-tag">#{t["tag"]["name"]}</Badge>
                    );
                  })
                : null}
              <table className="table mt-3">
                <tr>
                  <td className="p-2 font-weight-bold" width={"15%"}>
                    Amenities
                  </td>
                  <td className="p-2" width={"85%"}>
                    {listingData["formatted_amenities"]
                      ? listingData["formatted_amenities"].map((amenity) => {
                          if (amenity === "Courier Facility") {
                            return (
                              <Button
                                variant="secondary"
                                className="rounded-pill btn-xs mx-1"
                              >
                                <FaRegEnvelope size={"24"} className="me-2" />
                                {amenity}
                              </Button>
                            );
                          } else if (amenity === "Transport Facility") {
                            return (
                              <Button
                                variant="secondary"
                                className="rounded-pill btn-xs mx-1"
                              >
                                <FaTruck size={"24"} className="me-2" />
                                {amenity}
                              </Button>
                            );
                          } else if (amenity === "Home Delivery") {
                            return (
                              <Button
                                variant="secondary"
                                className="rounded-pill btn-xs mx-1"
                              >
                                <TbHomeCheck size={"24"} className="me-2" />
                                {amenity}
                              </Button>
                            );
                          }
                        })
                      : null}
                  </td>
                </tr>
              </table>
              {listingData.user ? (
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title as="h5">Seller Info</Card.Title>
                    <Card.Text>
                      {listingData.user.formatted_username}
                      {listingData.user.organization_name
                        ? ", " + listingData.user.organization_name
                        : null}{" "}
                      {listingData.user && listingData.user.city_id
                        ? ", " + listingData.user.city.name
                        : null}
                    </Card.Text>
                  </Card.Body>
                </Card>
              ) : null}

              <div className="row">
                <div className="col-md-6">
                  <button
                    type="button"
                    className="w-100 mb-3 btn btn-primary btn-lg"
                    onClick={() => _contactSeller()}
                  >
                    <FaMobileAlt />
                    &nbsp;Contact Seller
                  </button>
                </div>
                <div className="col-md-6">
                  {decodedJWT ? (
                    <button
                      type="button"
                      className={`w-100 mb-3 btn ${
                        isShortlisted ? "btn-success" : "btn-outline-primary"
                      } btn-lg`}
                      onClick={() => {
                        isShortlisted
                          ? _doListingShortlist(0)
                          : _doListingShortlist(1);
                        setIsShortListed(!isShortlisted);
                      }}
                    >
                      <i
                        className={`fi-heart${isShortlisted ? "-filled" : ""}`}
                      ></i>
                      &nbsp;
                      {isShortlisted
                        ? "Added to Shortlist"
                        : "Add to Shortlist"}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="w-100 mb-3 btn btn-secondary btn-lg"
                      onClick={() => setEnquiryModalShow(true)}
                    >
                      <FaEnvelope />
                      &nbsp;Send a Enquiry
                    </button>
                  )}
                </div>
              </div>
              <p style={{ fontSize: "14px" }} className="mb-2 text-right">
                <strong>Posted </strong> {listingData.formatted_posted_ago}
              </p>
            </Col>
            <Col>
              <Tabs defaultActiveKey="description" className="mt-3 mb-3">
                <Tab eventKey="description" title="Description">
                  {listingData.description ? (
                    <p>{listingData.description}</p>
                  ) : null}
                </Tab>
                <Tab
                  eventKey="additional_information"
                  title="Additional Information"
                >
                  {listingData.product_attributes_json &&
                  listingData.product_attributes_json.length ? (
                    <table className="table table-bordered">
                      <tbody>
                        {listingData.product_attributes_json.map((e) => {
                          return (
                            <tr>
                              <td width={"25%"} style={{ fontWeight: "bold" }}>
                                {e["label"]}
                              </td>
                              <td width={"75%"}>{e["description"]}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <div className="card">
                      <div className="card-body text-center">
                        Information not Available
                      </div>
                    </div>
                  )}
                </Tab>
              </Tabs>
            </Col>
          </Row>
        ) : null}
      </section>
      <section className="mt-4 container">
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="h4 mb-0">Similar Listings</h2>
        </div>
        {similarListingsData && similarListingsData.length ? (
          <div className="position-relative">
            <SwiperComponent
              modules={[Navigation, Pagination]}
              navigation={{
                prevEl: "#prevPropertiesSimilarAds",
                nextEl: "#nextPropertiesSimilarAds",
              }}
              pagination={{
                el: "#paginationPropertiesSimilarAds",
                clickable: true,
              }}
              loop
              spaceBetween={8}
              breakpoints={{
                0: { slidesPerView: 1 },
                500: { slidesPerView: 2 },
                768: { slidesPerView: 3 },
                992: { slidesPerView: 4 },
              }}
              className="pt-0 pb-4 mx-n2"
            >
              {similarListingsData.map((e, indx) => (
                <SwiperSlide key={indx} className="h-auto">
                  <ListingGridView
                    listing={e}
                    OnDoListingShortlist={_doListingShortlist}
                    className="h-100 mx-2"
                  />
                </SwiperSlide>
              ))}
            </SwiperComponent>

            <Button
              id="prevPropertiesSimilarAds"
              variant="prev"
              className="d-none d-xxl-block mt-n5 ms-n5"
            />
            <Button
              id="nextPropertiesSimilarAds"
              variant="next"
              className="d-none d-xxl-block mt-n5 me-n5"
            />
          </div>
        ) : (
          "No Listings with Similar Features"
        )}
      </section>
      <section className="mb-5 pb-2 pb-lg-4 container">
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="h4 mb-0">More from Seller</h2>
        </div>
        {sellerListingsData && sellerListingsData.length ? (
          <div className="position-relative">
            <SwiperComponent
              modules={[Navigation, Pagination]}
              navigation={{
                prevEl: "#prevPropertiesSellerAds",
                nextEl: "#nextPropertiesSellerAds",
              }}
              pagination={{
                el: "#paginationPropertiesSellerAds",
                clickable: true,
              }}
              loop
              spaceBetween={8}
              breakpoints={{
                0: { slidesPerView: 1 },
                500: { slidesPerView: 2 },
                768: { slidesPerView: 3 },
                992: { slidesPerView: 4 },
              }}
              className="pt-0 pb-4 mx-n2"
            >
              {sellerListingsData.map((e, indx) => (
                <SwiperSlide key={indx} className="h-auto">
                  <ListingGridView
                    listing={e}
                    OnDoListingShortlist={_doListingShortlist}
                    className="h-100 mx-2"
                  />
                </SwiperSlide>
              ))}
            </SwiperComponent>

            <Button
              id="prevPropertiesSellerAds"
              variant="prev"
              className="d-none d-xxl-block mt-n5 ms-n5"
            />
            <Button
              id="nextPropertiesSellerAds"
              variant="next"
              className="d-none d-xxl-block mt-n5 me-n5"
            />
          </div>
        ) : (
          "No More Listings from This Seller"
        )}
      </section>
    </DefaultLayout>
  );
}

export default Listing;
