import { useNavigate } from "react-router-dom";

const AppFooter = () => {
  const navigate = useNavigate();
  return (
    <footer className="footer bg-dark text-light">
      <div className="py-4 border-top border-light">
        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between py-2 container">
          <p className="order-lg-1 order-2 fs-sm mb-2 mb-lg-0">
            <span className="text-light opacity-60">
              © All rights reserved.{" "}
            </span>
            <a
              href="https://farmway.in/"
              className="nav-link-light fw-bold"
              target="_blank"
              rel="noreferrer"
            >
              Bharat Agri Mart
            </a>
          </p>
          <div className="d-flex flex-lg-row flex-column align-items-center lg-2 order-1 ms-lg-4 mb-lg-0 mb-4">
            <div className="d-flex flex-wrap fs-sm mb-lg-0 mb-4 pe-lg-4">
              <a
                className="nav-link-light px-2 mx-1"
                onClick={() => navigate("/about-us")}
              >
                About Us
              </a>
              <a
                className="nav-link-light px-2 mx-1"
                onClick={() => navigate("/privacy-policy")}
              >
                Privacy Policy
              </a>
              <a
                className="nav-link-light px-2 mx-1"
                onClick={() => navigate("/terms-conditions")}
              >
                Terms and Conditions
              </a>
              <a
                className="nav-link-light px-2 mx-1"
                onClick={() => navigate("/refund-policy")}
              >
                Refund Policy
              </a>
              {/* <a
                className="nav-link-light px-2 mx-1"
                onClick={() => navigate("/help")}
              >
                Help
              </a> */}
              <a
                className="nav-link-light px-2 mx-1"
                onClick={() => navigate("/contact-us")}
              >
                Contact Us
              </a>
            </div>
            <div className="d-flex align-items-center">
              <div className="ms-4 ps-lg-2 text-nowrap">
                <a
                  href="#"
                  className="btn btn-icon btn-xs rounded-circle btn-translucent-light ms-2"
                >
                  <i className="fi-facebook"></i>
                </a>
                <a
                  href="#"
                  className="btn btn-icon btn-xs rounded-circle btn-translucent-light ms-2"
                >
                  <i className="fi-instagram"></i>
                </a>
                <a
                  href="#"
                  className="btn btn-icon btn-xs rounded-circle btn-translucent-light ms-2"
                >
                  <i className="fi-youtube"></i>
                </a>
                <a
                  href="#"
                  className="btn btn-icon btn-xs rounded-circle btn-translucent-light ms-2"
                >
                  <i className="fi-x"></i>
                </a>
                <a
                  href="#"
                  className="btn btn-icon btn-xs rounded-circle btn-translucent-light ms-2"
                >
                  <i className="fi-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default AppFooter;
