import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  Breadcrumb,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { FaUserCircle } from "react-icons/fa";
import { doListingShortlist, fetchShortlistedListings } from "../services/meta";
import DefaultLayout from "../components/DefaultLayout";
import styled from "styled-components";
import PaginationComponent from "../components/PaginationComponent";
import { decodeJWT } from "../helpers/authHelper";
import toast from "react-hot-toast";
import { FaTable, FaTableCells, FaTableCellsLarge } from "react-icons/fa6";
import ListingListView from "../components/Listings/ListingListView";
import ListingGridView from "../components/Listings/ListingGridView";
const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  @media (min-width: 576px) {
    flex-direction: row;
    align-items: center;
    padding: 16px;
  }
`;

const ViewToggle = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  .icon {
    cursor: pointer;
    font-size: 24px;
    margin-left: 16px;
  }
  @media (min-width: 576px) {
    margin-left: auto;
    margin-top: 0;
  }
`;
function MyShortlists({ OnOpenLoginModal }) {
  const navigate = useNavigate();
  const decodedJWT = decodeJWT();
  const [defaultListingView, setDefaultListingView] = useState("grid");
  const [defaultSort, setDefaultSort] = useState("Created Time (DESC)");
  const [listings, setListings] = useState([]);
  const [pageContext, setPageContext] = useState({
    hasMorePage: false,
    page: 1,
    perPage: 10,
    sortColumn: "id",
    sortOrder: "DESC",
    totalRecords: 0,
  });
  const _updateSortOrder = (sortColumn, sortOrder) => {
    _fetchShortlistedListings({
      page: 1,
      per_page: pageContext.perPage,
      sort_column: sortColumn,
      sort_order: sortOrder,
    });
    setDefaultSort(`${sortColumn}_${sortOrder}`);
  };
  const _fetchShortlistedListings = async (params) => {
    setListings([]);
    await fetchShortlistedListings(params)
      .then((response) => {
        const { data } = response;
        if (data.data.shortlisted_listings) {
          setListings(data.data.shortlisted_listings);
          const _pageContext = data.data.page_context;
          setPageContext({
            hasMorePage: _pageContext.has_more_page,
            page: _pageContext.page,
            perPage: _pageContext.per_page,
            sortColumn: _pageContext.sort_column,
            sortOrder: _pageContext.sort_order,
            totalRecords: _pageContext.total,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const filterShortlistedListing = (
    page = null,
    per_page = null,
    sort_column = null,
    sort_order = null
  ) => {
    _fetchShortlistedListings({
      page: page ? page : 1,
      per_page: per_page ? per_page : pageContext.perPage,
      sort_column: sort_column ? sort_column : pageContext.sortColumn,
      sort_order: sort_order ? sort_order : pageContext.sortOrder,
    });
  };
  const _doListingShortlist = async (listingId, flag) => {
    if (decodedJWT) {
      await doListingShortlist({
        listing_id: listingId,
        flag,
      })
        .then((response) => {
          toast.success(response.data.message);
          filterShortlistedListing();
        })
        .catch((err) => {
          // toast.error(err.response.data.message);
        });
      return flag ? true : false;
    } else {
      OnOpenLoginModal();
      return false;
    }
  };
  useEffect(() => {
    _fetchShortlistedListings({
      sort_order: "DESC",
    });
  }, []);
  return (
    <DefaultLayout>
      <div
        className="position-absolute top-0 start-0 w-100 bg-dark"
        style={{ height: "398px" }}
      ></div>
      <Container as="section" className="content-overlay mb-md-4 py-5 mt-3">
        <Breadcrumb className="breadcrumb-light mb-3 mb-md-4 pt-md-3">
          <Breadcrumb.Item linkAs={Link}>Home</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} active>
            My Shortlists
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="bg-light shadow-sm rounded-3 p-4 p-md-5 mb-2">
          <div className="d-flex align-items-start justify-content-between">
            <Row>
              <Col xs={12} lg={12}>
                <h2 className="h4">My Shortlists</h2>
              </Col>
            </Row>
          </div>
          <Header>
            <DropdownButton
              title={`Sort By : ${defaultSort}`}
              variant="secondary"
              className="mt-0"
              size="sm"
            >
              {[
                {
                  id: "title_desc",
                  title: "Title (DESC)",
                  sortOrder: "DESC",
                  sortColumn: "title",
                },
                {
                  id: "title_asc",
                  title: "Title (ASC)",
                  sortOrder: "ASC",
                  sortColumn: "title",
                },
                {
                  id: "price_desc",
                  title: "Price (DESC)",
                  sortOrder: "DESC",
                  sortColumn: "price",
                },
                {
                  id: "price_asc",
                  title: "Price (ASC)",
                  sortOrder: "ASC",
                  sortColumn: "price",
                },
                {
                  id: "created_time_desc",
                  title: "Created Time (DESC)",
                  sortOrder: "DESC",
                  sortColumn: "created_time",
                },
                {
                  id: "created_time_asc",
                  title: "Created Time (ASC)",
                  sortOrder: "ASC",
                  sortColumn: "created_time",
                },
              ].map((e) => {
                return (
                  <Dropdown.Item
                    key={e.id}
                    onClick={() => {
                      _updateSortOrder(e.sortColumn, e.sortOrder);
                      setDefaultSort(e.title);
                    }}
                    active={e.id === `${e.sortColumn}_${e.sortOrder}`}
                  >
                    {e.title}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
            <ViewToggle active={defaultListingView === "list"}>
              <FaTableCells
                className={`icon ${
                  defaultListingView !== "list" ? "text-success" : ""
                }`}
                onClick={() => setDefaultListingView("grid")}
              />
              <FaTableCellsLarge
                className={`icon ${
                  defaultListingView === "list" ? "text-success" : ""
                }`}
                onClick={() => setDefaultListingView("list")}
              />
            </ViewToggle>
          </Header>
          {listings.length ? (
            <>
              {defaultListingView === "list" ? (
                <Row xs={1} sm={2} md={2} className="mx-0">
                  {listings.map((e) => {
                    return (
                      <ListingListView
                        listing={e}
                        OnDoListingShortlist={_doListingShortlist}
                      />
                    );
                  })}
                </Row>
              ) : (
                <Row xs={1} sm={2} md={4} className="mx-0">
                  {listings.map((e) => {
                    return (
                      <ListingGridView
                        listing={e}
                        OnDoListingShortlist={_doListingShortlist}
                      />
                    );
                  })}
                </Row>
              )}
              <div className="mt-2">
                <PaginationComponent
                  pageContext={pageContext}
                  OnFilter={filterShortlistedListing}
                />
              </div>
            </>
          ) : (
            <>
              <div className="text-center pt-2 pt-md-4 pt-lg-5 pb-2 pb-md-0">
                <i className="fi-heart display-6 text-muted mb-4"></i>
                <h2 className="h5 mb-2">{"Your shortlist is empty!"}</h2>
              </div>
            </>
          )}
        </div>
      </Container>
    </DefaultLayout>
  );
}

export default MyShortlists;
