import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
const BannerSection = ({ banners = [] }) => {
  return (
    <Swiper
      modules={[Autoplay]}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      spaceBetween={16}
      loop
      grabCursor
      className="swiper-nav-onhover shadow-lg swiper-pagination-light"
      style={{
        borderRadius: "10x",
      }}
    >
      {banners.length
        ? banners.map((e) => {
            return (
              <SwiperSlide className="d-flex">
                <img
                  src={e.formatted_image_file}
                  alt="Image"
                  className="banner-image"
                />
              </SwiperSlide>
            );
          })
        : null}
    </Swiper>
  );
};

export default BannerSection;
