import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Form, Button, Row, Col, Breadcrumb } from "react-bootstrap";
import DefaultLayout from "../../components/DefaultLayout";
import { FaUserCircle } from "react-icons/fa";
import { fetchProfile } from "../../services/meta";
function UserProfile() {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({});
  const _fetchProfile = async () => {
    await fetchProfile()
      .then((response) => {
        const { data } = response;
        setProfileData(data.data.user);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  useEffect(() => {
    _fetchProfile();
  }, []);
  return (
    <>
      <DefaultLayout>
        <div
          className="position-absolute top-0 start-0 w-100 bg-dark"
          style={{ height: "398px" }}
        ></div>
        <Container as="section" className="content-overlay mb-md-4 py-5 mt-3">
          <Breadcrumb className="breadcrumb-light mb-3 mb-md-4 pt-md-3">
            <Breadcrumb.Item linkAs={Link} onClick={() => navigate("/")}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} active>
              Profile
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="bg-light shadow-sm rounded-3 p-4 p-md-5 mb-2">
            <div className="d-flex align-items-start justify-content-between pb-4 mb-2">
              <div className="d-flex align-items-start">
                <div className="position-relative flex-shrink-0">
                  <FaUserCircle size={"75px"} />
                </div>
                <div className="ps-3 ps-sm-4">
                  <h3 className="h5">
                    {profileData && profileData.formatted_username}
                  </h3>
                  <ul className="list-unstyled fs-sm mb-0">
                    {profileData && profileData.email ? (
                      <li className="d-flex text-nav text-break">
                        <i className="fi-mail opacity-60 mt-1 me-2"></i>
                        <span>{profileData && profileData.email}</span>
                      </li>
                    ) : null}
                    <li className="d-flex text-nav text-break">
                      <i className="fi-phone opacity-60 mt-1 me-2"></i>
                      <span>
                        (+91) {profileData && profileData.mobile_number}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <Button
                onClick={() => navigate("/profile/edit")}
                className="btn btn-primary rounded-pill"
              >
                <i className="fi-edit mt-n1 me-2"></i>
                Edit Profile
              </Button>
            </div>
            <Form>
              <Row className="pt-4 mt-3">
                <Col xs={12} lg={3}>
                  <h2 className="h4">Basic Details</h2>
                </Col>
                <Col xs={12} lg={9}>
                  <div className="border rounded-3 p-3">
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="" className="form-label fw-bold">
                          First Name
                        </label>
                        <div>
                          {profileData && profileData.first_name
                            ? profileData.first_name
                            : "-"}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="" className="form-label fw-bold">
                          Last Name
                        </label>
                        <div>
                          {profileData && profileData.last_name
                            ? profileData.last_name
                            : "-"}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="" className="form-label fw-bold">
                          Mobile Number *
                        </label>
                        <div>
                          {profileData && profileData.mobile_number
                            ? profileData.mobile_number
                            : "-"}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="" className="form-label fw-bold">
                          Email
                        </label>
                        <div>
                          {profileData && profileData.email
                            ? profileData.email
                            : "-"}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="" className="form-label fw-bold">
                          Alternative Number
                        </label>
                        <div>
                          {profileData && profileData.alternative_number
                            ? profileData.alternative_number
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </Container>
      </DefaultLayout>
    </>
  );
}

export default UserProfile;
