import DefaultLayout from "../components/DefaultLayout";
function PrivacyPolicy() {
  return (
    <>
      <DefaultLayout>
        <section className="bg-secondary py-5 pb-0">
          <div className="py-5 pb-0 container">
            <nav aria-label="breadcrumb" className="pt-3 mb-4">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Privacy Policy
                </li>
              </ol>
            </nav>
            <div className="px-0 col-lg-8 col-md-10">
              <h1 className="pb-2">Privacy Policy</h1>
            </div>
          </div>
        </section>
        <section className="position-relative bg-white rounded-xxl-4 mb-5 py-2 zindex-5 container">
          <div className="p-5">
            <p>
              <span>
                The Privacy Policy of&nbsp;<i>www.farmway.in</i>
                &nbsp;(hereinafter referred to as “site &quot;) detailed herein
                below governs the collection, possession, storage, handling and
                dealing of personal identifiable information/data and sensitive
                personal data (hereinafter collectively referred to as
                “information”) of the users of the site.
              </span>
            </p>

            <p>
              <span>
                All the users must read and understand this Privacy Policy as it
                has been formulated to safeguard the user’s privacy. This
                Privacy Policy also outlines the ways the users can ensure
                protection of their personal identifiable information.
              </span>
            </p>

            <p>
              <span>
                You must accept the contents of this Policy in order to use or
                continue using our site. This Privacy Policy detailed herein is
                also applicable to user of the site or mobile application
                through mobile or any other similar device.
              </span>
            </p>

            <p>
              <span>
                <br />
                <b>COLLECTION OF INFORMATION</b>
              </span>
            </p>

            <p>
              <span>
                We confirm that we collect those information from you which is
                required to extend the services available on the site.
              </span>
            </p>

            <ul>
              <li>
                <span>
                  At the time of signing up and registration with the site, we
                  collect user information including name, company name, email
                  address, phone/mobile number, postal address and other
                  business information which may also include business statutory
                  details and GST registration numbers.
                  <br />
                  In this regard, we may also record conversations and archive
                  correspondence between users and the representatives of the
                  site (including the additional information, if any) in
                  relation to the services for quality control or training
                  purposes.
                </span>
              </li>
              <li>
                <span>
                  In relation to our paid services, we may collect personal
                  information of a more sensitive nature which includes bank
                  account numbers and related details to facilitate the sale or
                  purchase of the services available on the site.
                </span>
              </li>
              <li>
                <span>
                  We also gather and stores the user’s usage statistics such as
                  IP addresses, pages viewed, user behaviour pattern, number of
                  sessions and unique visitors, browsing activities, browser
                  software operating system etc. for analysis, which helps us to
                  provide improved experience and value-added services to you.
                </span>
              </li>
              <li>
                <span>
                  Once a user registers, the user is no longer anonymous to us
                  and thus all the information provided by you shall be stored,
                  possessed in order to provide you with the requested services
                  and as may be required for compliance with statutory
                  requirements.
                </span>
              </li>
              <li>
                <span>
                  User’s registration with us and providing information is
                  intended for facilitating the users in its business.
                </span>
              </li>
              <li>
                <span>
                  We retain user provided Information for as long as the
                  Information is required for the purpose of providing services
                  to you or where the same is required for any purpose for which
                  the Information can be lawfully processed or retained as
                  required under any statutory enactments or applicable laws.
                </span>
              </li>
            </ul>

            <p>
              <span>
                User may update, correct, or confirm provided information by
                logging on to their accounts on the site or by sending a request
                to&nbsp;<i>support@bharatagrimart.com</i>. The requested changes
                may take reasonable time due to verification process and server
                cache policies. In case you would like to receive a copy of our
                information held by us for porting to another service, please
                contact us with your request at the email address above.
              </span>
            </p>

            <p>
              <span>
                Users may also choose to delete or deactivate their accounts on
                the site by sending a request to{" "}
                <i>support@bharatagrimart.com</i>. We will evaluate such
                requests on a case-to-case basis and take the requisite action
                as per applicable law. In this regard, please note that
                information sought to be deleted may remain with us in archival
                records for the purpose of compliance of statutory enactments,
                or for any other lawful purpose. Therefore, users are requested
                to carefully evaluate what types of information they would like
                to provide to us at the time of registration.
              </span>
            </p>

            <p>
              <span>
                <br />
                <b>PURPOSE AND USAGE OF INFORMATION</b>
                <br />
                The following are the purposes of collecting the Information:
              </span>
            </p>

            <ul>
              <li>
                <span>
                  For the verification of your identity, eligibility,
                  registration, e-mail IDs, and to provide customized services.
                </span>
              </li>
              <li>
                <span>
                  For facilitating the services offered/available on the site.
                </span>
              </li>
              <li>
                <span>
                  For advertising, marketing, displaying &amp; publication.
                </span>
              </li>
              <li>
                <span>
                  For enabling communication with the users of the site, so that
                  the users may fetch maximum business opportunities.
                </span>
              </li>
              <li>
                <span>For generating business enquires and trade leads.</span>
              </li>
              <li>
                <span>
                  For sending communications, notifications, newsletters and
                  customized mailers etc.
                </span>
              </li>
              <li>
                <span>
                  For providing You with a convenient and seamless user
                  experience with minimal points of friction.
                </span>
              </li>
            </ul>

            <p>
              <span>
                Please get in touch with us at the above email address in case
                you would like to object to any purpose of data processing.
                However, please note that if you object or withdraw consent to
                process data as above, we may discontinue providing you with
                services through our site.
              </span>
            </p>

            <p>
              <span>
                <br />
                <b>DISCLOSURE OF INFORMATION</b>
                <br />
                Information we may collect from you may be disclosed and
                transferred to external service providers who we rely on to
                provide services to us or to you directly. For instance,
                information may be shared with
              </span>
            </p>

            <ul>
              <li>
                <span>
                  Affiliated companies for better efficiency, more relevancy,
                  innovative business matchmaking and better personalised
                  services.
                </span>
              </li>
              <li>
                <span>
                  Government or regulatory or law enforcement agencies, as
                  mandated under statutory enactment, for verification of
                  identity or for prevention, detection, investigation including
                  cyber incidents, prosecution and punishment of offences.
                </span>
              </li>
              <li>
                <span>
                  Service provider including but not limited to payment,
                  customer and cloud computing service provider (“Third Party”)
                  engaged for facilitating service requirements of user.
                </span>
              </li>
              <li>
                <span>
                  Business partners for sending their business offers to the
                  users, which are owned and offered by them solely without
                  involvement of the site.
                </span>
              </li>
            </ul>

            <p>
              <span>
                Links to the websites of any of the above may be available on
                the site as a convenience to user(s) and the site does not have
                any control over such websites. The usage of such websites by
                the user will be governed by their respective Privacy Policies
                and the present Privacy Policy will not apply to usage of such
                websites. The users of such websites are cautioned to read the
                privacy policies of such websites.
              </span>
            </p>

            <p>
              <span>
                Please get in touch with us at the above email address in case
                you would like to object to any purpose of data processing.
                However, please note that if you object or withdraw consent to
                process data as above, we may discontinue providing you with
                services through our site.
              </span>
            </p>

            <p>
              <span>
                <br />
                In relation to such disclosures, receiving parties have
                consented and confirmed that:
              </span>
            </p>

            <ul>
              <ol>
                <li>
                  <span>
                    There shall be limited disclosure of any Information to its
                    Directors, officers, employees, agents or representatives
                    who have a need to know such Information in connection with
                    the business transaction and are only permitted to use your
                    Information in connection with the said purpose,
                  </span>
                </li>
                <li>
                  <span>
                    They shall keep the Information confidential and secure by
                    using a reasonable degree of care, and
                  </span>
                </li>
                <li>
                  <span>
                    They shall not disclose any Information received by them
                    further and must abide by the Privacy Policy of the site.
                  </span>
                </li>
              </ol>
            </ul>

            <p>
              <span>
                Please keep in mind that whenever a user post personal &amp;
                business information online, the same becomes accessible to the
                public and the users may receive messages/emails from visitors
                of the site.
              </span>
            </p>

            <p>
              <span>
                <br />
                <b>REASONABLE PROTECTION OF INFORMATION</b>
              </span>
            </p>

            <p>
              <span>
                We employ commercially reasonable and industry-standard security
                measures to prevent unauthorized access, maintain data accuracy
                and ensure proper use of information we receive.
              </span>
            </p>

            <p>
              <span>
                These security measures are both electronic as well as physical
                but at the same time no data transmission over the Internet can
                be guaranteed to be 100% secure.
              </span>
            </p>

            <p>
              <span>
                We strive to protect the User Information, although we cannot
                ensure the security of Information furnished/transmitted by the
                users to us.
              </span>
            </p>

            <p>
              <span>
                We&nbsp;recommend&nbsp;you not to disclose password of your
                email address, online bank transaction and other important
                credentials to our employees / agents / affiliates/ personnel,
                as we do not ask for the same.
              </span>
            </p>

            <p>
              <span>
                We recommend that registered users not to share their site’s
                account password and also to sign out of their account when they
                have completed their work. This is to ensure that others cannot
                access Information of the users and correspondence, if the user
                shares the computer with someone else or is using a computer in
                a public place
              </span>
            </p>

            <p>
              <span>
                We&nbsp;recommend&nbsp;you not to disclose password of your
                email address, online bank transaction and other important
                credentials&nbsp;to our employees / agents / affiliates/
                personnel, as we do not ask for the same
              </span>
            </p>

            <p>
              <span>
                <br />
                <b>COOKIES</b>
                <br />
                We, and third parties with whom we partner, may use cookies,
                pixel tags, web beacons, mobile device IDs, “flash cookies” and
                similar files or technologies to collect and store information
                in respect to your use of the site and track your visits to
                third party websites.
              </span>
            </p>

            <p>
              <span>
                We also use cookies to recognize your browser software and to
                provide features such as recommendations and personalization.
              </span>
            </p>

            <p>
              <span>
                Third parties whose products or services are accessible or
                advertised through the site, including social media sites, may
                also use cookies or similar tools, and we advise you to check
                their privacy policies for information about their cookies and
                the practices followed by them. We do not control the practices
                of third parties and their privacy policies govern their
                interactions with you.
              </span>
            </p>

            <p>
              <span>
                <br />
                <b>DATA COLLECTION RELATING TO CHILDREN</b>
                <br />
                We strongly believe in protecting the privacy of children. In
                line with this belief, we do not knowingly collect or maintain
                Personally Identifiable Information on our Site from persons
                under 18 years of age, and no part of our Site is directed to
                persons under 18 years of age. If you are under 18 years of age,
                then please do not use or access our services at any time or in
                any manner. We will take appropriate steps to delete any
                Personally Identifiable Information of persons less than 18
                years of age that has been collected on our Site without
                verified parental consent upon learning of the existence of such
                Personally Identifiable Information.
              </span>
            </p>

            <p>
              <span>
                If we become aware that a person submitting personal information
                is under 18, we will delete the account and all related
                information as soon as possible. If you believe we might have
                any information from or about a child under 18 please contact us
                at&nbsp;<i>support@bharatagrimart.com</i>
              </span>
            </p>

            <p>
              <span>
                <br />
                <b>DATA TRANSFERS</b>
                <br />
                User Information that we collect may be transferred to, and
                stored at, any of our affiliates, partners or service providers
                which may be inside or outside the country you reside in. By
                submitting your personal data, you agree to such transfers.
              </span>
            </p>

            <p>
              <span>
                Your Personal Information may be transferred to countries that
                do not have the same data protection laws as the country in
                which you initially provided the information. When we transfer
                or disclose your Personal Information to other countries, we
                will protect that information as described in this Privacy
                Policy. relevant, we will ensure appropriate contractual
                safeguards to ensure that your information is processed with the
                highest standards of transparency and fairness.
              </span>
            </p>

            <p>
              <span>
                <br />
                <b>CONTACT US</b>
              </span>
            </p>

            <p>
              <span>
                Please contact us regarding any questions, clarifications, or
                grievances. Please email us at&nbsp;
                <i>support@bharatagrimart.com</i>
                <br />
                <br />
                <br />
                <b>CHANGES IN PRIVACY POLICY</b>
                <br />
                The policy may change from time to time, so users are requested
                to check it periodically.
              </span>
            </p>
          </div>
        </section>
      </DefaultLayout>
    </>
  );
}

export default PrivacyPolicy;
